import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import { Observer } from 'mobx-react-lite';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import iconLinkBlank from '@/newcar/ui/detail/img/icon-link-blank.svg';
import * as styles from '@/newcar/ui/detail/organism/modal/ModalSP.module.styl';

interface ModalMaintenanceSPProps {
  isShow: boolean;
}

const ModalMaintenanceSP: FC<ModalMaintenanceSPProps> = ({ isShow }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isShow) {
      setStyle({});
    } else {
      // コンテンツの高さが一番高いところで揃ってしまうので非表示の時は高さを0にする
      setTimeout(() => setStyle({ height: '0px' }), 400);
    }
  }, [isShow]);

  // メンテプランごとのスタイルを返す
  const planStyle = (id: NEWCAR_CONST.MAINTENANCE.PLAN) => {
    switch (id) {
      case NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM:
        return styles.platinum;
      case NEWCAR_CONST.MAINTENANCE.PLAN.GOLD:
        return styles.gold;
      case NEWCAR_CONST.MAINTENANCE.PLAN.SILVER:
        return styles.silver;
      default:
        return '';
    }
  };

  return (
    <Observer>
      {() => (
        <div className={`${styles.content} is-padding-5`} style={style} data-type="maintenance-setting">
          <h2 className="has-text-centered is-margin-bottom-3 is-size-3 has-text-weight-light">メンテプランをえらぶ</h2>
          <div className="is-margin-bottom-6">
            {simulationManagerStore.maintenancePlanList.map((value, index) => (
              <div className={`${styles.radioRow} ${styles.isMainte} is-padding-1`} key={index.toString()}>
                <input
                  type="radio"
                  name="mainte"
                  id={`mainte-${index}`}
                  checked={value.isChecked}
                  onClick={() => simulationManagerStore.changeMaintenance(value.id)}
                  data-gtm="gtm-newcar-simulator-modalMainte-maintePlan"
                />
                <label htmlFor={`mainte-${index}`} className="box is-block">
                  {index === 0 && (
                    <p className={`${styles.recommend} is-margin-top-3 is-margin-left-4 has-background-primary has-text-weight-semibold has-text-white is-size-6`}>
                      おすすめ
                    </p>
                  )}

                  <div className={`${styles.mainteHeader} is-padding-3`}>
                    <p className={`${planStyle(value.id)} is-size-5 has-text-weight-bold is-padding-left-3 is-padding-right-3`}>
                      {value.name}
                    </p>
                    <p className="is-size-5 has-text-black-ter is-padding-right-2">
                      {(value.price && value.price > 0) ? `+${CARMO_CONST.COMMA_FORMAT(value.price)}円` : ''}
                    </p>
                  </div>

                  {value.overview && (
                    <div className={`${styles.overview} is-size-5 has-text-black-ter is-margin-left-4 is-margin-right-4 is-padding-top-3 is-padding-bottom-3`}>
                      {value.id === NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM && (
                        <>
                          <h3 className={styles.overviewHeading}>長く安心して車に乗りたい方におすすめ！</h3>
                          <span className={styles.overviewNotice}>
                            ※リース期間6年～11年でご加入いただけます
                          </span>
                          <ul className={styles.overviewList}>
                            <li className={styles.overviewListItem}>車検</li>
                            <li className={styles.overviewListItem}>
                              原状回復費用補償
                              <span className={styles.overviewAsterisk}>※1</span>
                            </li>
                            <li className={styles.overviewListItem}>エンジンオイル、オイルフィルター交換など</li>
                            <li className={styles.overviewListItem}>タイヤメンテ、ブレーキオイル交換など</li>
                            <li className={styles.overviewListItem}>
                              メーカー特別保証終了後の延長
                              <span className={styles.overviewAsterisk}>※2</span>
                            </li>
                          </ul>
                        </>
                      )}
                      {value.id === NEWCAR_CONST.MAINTENANCE.PLAN.GOLD && (
                        <>
                          <h3 className={styles.overviewHeading}>より安心してカーライフを楽しみたい方におすすめ！</h3>
                          <ul className={styles.overviewList}>
                            <li className={styles.overviewListItem}>車検</li>
                            <li className={styles.overviewListItem}>
                              原状回復費用補償
                              <span>※1</span>
                            </li>
                            <li className={styles.overviewListItem}>エンジンオイル、オイルフィルター交換など</li>
                            <li className={styles.overviewListItem}>タイヤメンテ、ブレーキオイル交換など</li>
                          </ul>
                        </>
                      )}
                      {value.id === NEWCAR_CONST.MAINTENANCE.PLAN.SILVER && (
                        <>
                          <h3 className={styles.overviewHeading}>最低限をカバーしたベーシックなプラン！</h3>
                          <ul className={styles.overviewList}>
                            <li className={styles.overviewListItem}>車検</li>
                            <li className={styles.overviewListItem}>
                              原状回復費用補償
                              <span>※1</span>
                            </li>
                            <li className={styles.overviewListItem}>エンジンオイル、オイルフィルター交換など</li>
                          </ul>
                        </>
                      )}
                    </div>
                  )}
                </label>
              </div>
            ))}
          </div>

          {/* 説明 */}
          {simulationManagerStore.maintenancePlanList.length === 0 ? (
            <div className="column is-padding-4 has-text-centered">
              ご選択いただいている車種ではメンテナンスプランをご利用いただくことはできません。
            </div>
          ) : (
            <div className="columns is-mobile">
              <div className="column is-one-fifth is-hidden-touch" />
              <div className="column">
                <p className={`has-text-black-ter ${styles.maintenanceMemo}`}>※1）契約満了時に返却する場合の補償</p>
                <p className={`has-text-black-ter ${styles.maintenanceMemo}`}>※2）リース期間満了または走行距離10万km</p>
              </div>
              <div className="column is-one-fifth is-hidden-touch" />
            </div>
          )}
          <div className="has-text-centered is-margin-top-5 is-margin-bottom-5">
            <a href={NEWCAR_DEFINE.PATH.MAINTENANCE} target="_blank" className="is-size-5 has-text-link is-vcentered" data-gtm="gtm-newcar-simulator-modalMainte-toMaintePageLink" rel="noreferrer">
              メンテナンスプランの詳細を見る
              <img className={`is-margin-left-3 ${styles.verticalAlignTextTop}`} width={15} height={15} src={iconLinkBlank} alt="リンクアイコン" />
            </a>
          </div>
          <div className="column is-one-fifth is-hidden-touch" />
        </div>
      )}
    </Observer>
  );
};

export default ModalMaintenanceSP;
