// extracted by mini-css-extract-plugin
export const borderLikeCard = "Information-module--border-like-card--1isjS";
export const titleWrap = "Information-module--title-wrap--TT-ML";
export const carName = "Information-module--car-name--30HlZ";
export const carNameAddition = "Information-module--car-name-addition--fO8LB";
export const makersiteIconWrap = "Information-module--makersite-icon-wrap--28Vhb";
export const mainSpacing = "Information-module--main-spacing--1qmXI";
export const mainImg = "Information-module--main-img--1JTgU";
export const mainInfo = "Information-module--main-info--2Qk9G";
export const maxWidthColor = "Information-module--max-width-color--3g2Lz";
export const isMarginMinus = "Information-module--is-margin-minus--33cpj";
export const inner = "Information-module--inner--2xpd_";
export const minVehiclePrice = "Information-module--min-vehicle-price--2Ltqw";
export const innerLight = "Information-module--inner-light--3Bc9T";
export const boxLeftLineGrey = "Information-module--box-left-line-grey--31Wjp";
export const roundedBox = "Information-module--rounded-box--ECONA";
export const blueButton = "Information-module--blue-button--3Bfqj";
export const isShaped = "Information-module--is-shaped--2bqGn";
export const iconArrowRightBig = "Information-module--icon-arrow-right-big--35jpR";
export const makerSiteBtn = "Information-module--maker-site-btn--2lCfM";
export const plusBtn = "Information-module--plus-btn--1fkgh";
export const active = "Information-module--active--2M2MO";
export const isUnderline = "Information-module--is-underline--3B-zN";
export const isBorderGreyLighter = "Information-module--is-border-grey-lighter--2tg4k";
export const balloonCampaign = "Information-module--balloon-campaign--3GoP9";
export const naviPlanBalloon = "Information-module--navi-plan-balloon--3TMUV";
export const naviPlanText = "Information-module--navi-plan-text--3TBT5";
export const naviPlanTextMin = "Information-module--navi-plan-text-min--3kKPA";
export const anchorLinkButton = "Information-module--anchor-link-button--2syiA";
export const salesStopMessage = "Information-module--sales-stop-message--3Y3xl";