// extracted by mini-css-extract-plugin
export const reviewsContainer = "Reviews-module--reviews-container--BIX-h";
export const reviewsHeading = "Reviews-module--reviews-heading--1Bdcd";
export const reviewsSubheading = "Reviews-module--reviews-subheading--32kUU";
export const reviewsContent = "Reviews-module--reviews-content--2QHdu";
export const reviewsNotice = "Reviews-module--reviews-notice--3UUZ6";
export const reviewsUserCommentSpeeachBalloon = "Reviews-module--reviews-user-comment-speeach-balloon--3XiGM";
export const reviewsUserCommentList = "Reviews-module--reviews-user-comment-list--2ViFc";
export const reviewsUserCommentContainer = "Reviews-module--reviews-user-comment-container--3cBdU";
export const isFirst = "Reviews-module--is-first--1Asps";
export const isSecond = "Reviews-module--is-second--lGUPe";
export const isThird = "Reviews-module--is-third--3WVuD";
export const isFourth = "Reviews-module--is-fourth--yfscH";
export const reviewsUserCommentItem = "Reviews-module--reviews-user-comment-item--2Bwq_";
export const reviewsUserCommentText = "Reviews-module--reviews-user-comment-text--3qWyv";
export const reviewsUserCommentTextMin = "Reviews-module--reviews-user-comment-text-min--1_CJJ";
export const reviewsCardSingleContainer = "Reviews-module--reviews-card-single-container--wHli1";
export const reviewsCardSingleItem = "Reviews-module--reviews-card-single-item--1tZTn";
export const reviewsCardSingleHeading = "Reviews-module--reviews-card-single-heading--GFS64";
export const reviewsCardSingleProfile = "Reviews-module--reviews-card-single-profile--E-PWw";
export const reviewsCardSingleRating = "Reviews-module--reviews-card-single-rating--9U_q4";
export const reviewsCardSingleDescription = "Reviews-module--reviews-card-single-description--1mQG4";