import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import { Observer } from 'mobx-react-lite';
import CARMO_CONST from '@/common/util/const';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import ColorSquare from '@/newcar/ui/detail/molecule/ColorSquare';
import * as styles from '@/newcar/ui/detail/organism/modal/ModalSP.module.styl';

interface ModalBodyColorSPProps {
  isShow: boolean;
}

const ModalBodyColorSP: FC<ModalBodyColorSPProps> = ({ isShow }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isShow) {
      setStyle({});
    } else {
      // コンテンツの高さが一番高いところで揃ってしまうので非表示の時は高さを0にする
      setTimeout(() => setStyle({ height: '0px' }), 400);
    }
  }, [isShow]);

  return (
    <Observer>
      {() => (
        <div className={`${styles.content} is-padding-5`} style={style} data-type="bodycolor-setting">
          <h2 className="has-text-centered is-margin-bottom-3 is-size-3 has-text-weight-light">ボディカラーをえらぶ</h2>
          <div className="is-margin-bottom-6">
            {simulationManagerStore.bodyColorList.map((value, index) => (
              <div className={`${styles.radioRow} ${styles.isBodyColor} is-padding-1`} key={index.toString()}>
                <input
                  type="radio"
                  name="body-color"
                  id={`body-color-${value.id}`}
                  checked={value.isChecked}
                  onClick={() => simulationManagerStore.changeBodyColor(value.id)}
                  data-gtm="gtm-newcar-simulator-modalBodyColor-bodycolor"
                />
                <label htmlFor={`body-color-${value.id}`} className="box is-padding-5">
                  <div className="columns is-mobile is-vcentered is-full-width">
                    <div className="column is-narrow is-flex has_vertical_centered">
                      <ColorSquare
                        width={24}
                        height={24}
                        colorCode1={value.colorCode1}
                        colorCode2={value.colorCode2}
                      />
                    </div>

                    <div className="column is-padding-3">
                      <span className="is-size-5 has-text-black-ter">
                        {value.bodyColorName}
                      </span>
                    </div>

                    <div className="column is-narrow">
                      <span className="is-size-5">
                        {(value.price && value.price > 0) ? `+${CARMO_CONST.COMMA_FORMAT(value.price)}円` : ''}
                      </span>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </Observer>
  );
};

export default ModalBodyColorSP;
