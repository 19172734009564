/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable default-case */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */
export enum BODY_ID {
  /** 未選択 */
  UNSELECTED = 0,
  /** セダン */
  SEDAN = 1,
  /** ステーションワゴン */
  STATION_WAGON = 2,
  /** ミニバン・ワゴン */
  MINIVAN = 3,
  /** SUV・クロカン */
  SUV = 4,
  /** 軽自動車 */
  MINI_CAR = 5,
  /** コンパクトカー */
  COMPACT_CAR = 6,
  /** ハッチバック */
  HATCH_BACK = 7,
  /** クーペ・オープンカー */
  COUPE = 9,
  /** その他 */
  OTHER = 10,
}

export const BODY_DATA = [
  {
    id: BODY_ID.UNSELECTED,
    name: '',
    metaName: '',
    key: '',
    isUnselected: true,
    modalName: '',
    count: 0,
  },
  {
    id: BODY_ID.MINI_CAR,
    name: '軽自動車',
    metaName: '軽自動車(MINI)',
    key: 'mini',
    isUnselected: false,
    modalName: '軽自動車',
    count: 0,
  },
  {
    id: BODY_ID.COMPACT_CAR,
    name: 'コンパクトカー',
    metaName: 'コンパクトカー(COMPACT)',
    key: 'compact',
    isUnselected: false,
    modalName: 'コンパクト',
    count: 0,
  },
  {
    id: BODY_ID.HATCH_BACK,
    name: 'ハッチバック',
    metaName: 'ハッチバック(HATCHBACK)',
    key: 'hatchback',
    isUnselected: false,
    modalName: 'ハッチバック',
    count: 0,
  },
  {
    id: BODY_ID.MINIVAN,
    name: 'ミニバン・ワゴン',
    metaName: 'ミニバン・ワゴン(MINIVAN)',
    key: 'minivan',
    isUnselected: false,
    modalName: 'ミニバン',
    count: 0,
  },
  {
    id: BODY_ID.SUV,
    name: 'SUV・クロカン',
    metaName: 'SUV・クロカン(SUV)',
    key: 'suv',
    isUnselected: false,
    modalName: 'SUV',
    count: 0,
  },
  {
    id: BODY_ID.SEDAN,
    name: 'セダン',
    metaName: 'セダン(SEDAN)',
    key: 'sedan',
    isUnselected: false,
    modalName: 'セダン',
    count: 0,
  },
  {
    id: BODY_ID.STATION_WAGON,
    name: 'ステーションワゴン',
    metaName: 'ステーションワゴ(STATIONWAGON)',
    key: 'stationwagon',
    isUnselected: false,
    modalName: 'ステーションワゴン',
    count: 0,
  },
  {
    id: BODY_ID.COUPE,
    name: 'クーペ・オープンカー',
    metaName: 'クーペ・オープンカー(COUPE)',
    key: 'coupe',
    isUnselected: false,
    modalName: 'クーペ',
    count: 0,
  },
  {
    id: BODY_ID.OTHER,
    name: 'その他',
    metaName: 'その他(OTHER)',
    key: 'other',
    isUnselected: false,
    modalName: 'その他',
    count: 0,
  },
];

// 内部関数idでデータ探索
const bodyTypeDataFind = (id: BODY_ID): number => {
  const target = BODY_DATA.findIndex((data) => (data.id === id));
  if (target >= 0) {
    return target;
  }
  return BODY_DATA.findIndex((data) => (data.id === BODY_ID.OTHER));
};

// ID → メーカー名
export const BODY_NAME = (id: BODY_ID): string => BODY_DATA[bodyTypeDataFind(id)].name;

// ID → メーカー名(英語)
export const BODY_META_NAME = (id: BODY_ID): string => BODY_DATA[bodyTypeDataFind(id)].metaName;

// ID → KEY
export const BODY_ID_TO_KEY = (id: BODY_ID): string => BODY_DATA[bodyTypeDataFind(id)].key;

// KEY → ID
export const BODY_KEY_TO_ID = (key: string): BODY_ID => {
  const target = BODY_DATA.find((data) => (data.key === key));
  if (target) {
    return target.id;
  }
  throw new Error('Invalid value');
};

// ID → モーダル名
export const BODY_MODAL_NAME = (id: BODY_ID): string => BODY_DATA[bodyTypeDataFind(id)].modalName;
