import React, { FC } from 'react';
import CARMO_CONST from '@/common/util/const';
import CarDigest from '@/common/core/model/carDigest';
import SEOBase from '@/newcar/ui/common/organism/SEO';

type DetailSEOProps = Pick<CarDigest, 'name' | 'makerId' | 'bodyType' | 'taxedMinPrice11'>;

const SEO: FC<DetailSEOProps> = ({
  name, makerId, taxedMinPrice11,
}) => {
  // title
  // "○万円台"の数字部分を生成
  const amount = taxedMinPrice11 < 10000
    ? `0.${String(taxedMinPrice11).slice(0, -3)}`
    : String(taxedMinPrice11).slice(0, -4);
  const titleName = `${CARMO_CONST.MAKER.NAME(makerId)} ${name}`;
  const title = `${titleName}の新車カーリース・サブスク｜定額カルモくん 月額${amount}万円台から`;

  // description
  const DESCRIPTION_PREFIX = '【ネット割最大49,500円】';
  const DESCRIPTION_SUFFIX = `の新車が月々${amount}万円台から｡頭金・ボーナス払い無しで毎月定額、税金コミコミで安心の新車カーリース（車のサブスク）ならおトクにマイカー 定額カルモくん。メンテナンスも月額定額、契約満了後に車がもらえるプランもご用意。`;
  const description = `${DESCRIPTION_PREFIX}${titleName}${DESCRIPTION_SUFFIX}`;

  return (
    <SEOBase
      title={title}
      description={description}
      ogTitle={title}
      ogDescription={description}
      ogUrl={`${process.env.GATSBY_SITE_URL}`}
      twitterTitle={title}
      twitterDescription={description}
    />
  );
};

export default SEO;
