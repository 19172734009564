/* eslint-disable max-len */
import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import { navigate } from 'gatsby';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import searchCarStoreContext from '@/newcar/ui/common/context/searchCarStoreContext';
import ColorSquare from '@/newcar/ui/detail/molecule/ColorSquare';
import * as styles from '@/newcar/ui/detail/organism/modal/ModalSP.module.styl';

interface ModalResultProps {
  isShow: boolean;
  thumbnailUrl: string;
}

const ModalResult: FC<ModalResultProps> = ({ isShow, thumbnailUrl }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const searchCarStore = useContext(searchCarStoreContext);

  const carDigest = searchCarStore.lastViewCarDigest;
  const carName = carDigest ? carDigest.name : '';

  // グレード
  const grade = simulationManagerStore.nowSelectGrade;
  const gradeName = grade ? grade.name : '';
  const gradeSub = grade ? grade.sub : '';

  // 金額
  const nomalPrice = simulationManagerStore.nowSelectBasePrice ? simulationManagerStore.nowSelectBasePrice.toLocaleString() : '';
  const discountPrice = simulationManagerStore.nowSelectPrice ? simulationManagerStore.nowSelectPrice.toLocaleString() : '';

  // ボディカラー
  const bodyColor = simulationManagerStore.nowSelectBodyColor;
  const bodyColorName = bodyColor ? bodyColor.bodyColorName : '';
  const colorCode1 = bodyColor ? bodyColor.colorCode1 : '';
  const colorCode2 = bodyColor ? bodyColor.colorCode2 : '';

  // オプション
  const hasCarNavi = simulationManagerStore.nowSelectCarNavi !== '100001';
  const carNaviPrice = simulationManagerStore.carNaviList[0] ? simulationManagerStore.carNaviList[0].price?.toLocaleString() : '';
  const hasBackCamera = simulationManagerStore.nowSelectBackCamera !== '100002';
  const backCameraPrice = simulationManagerStore.backCameraList[0] ? simulationManagerStore.backCameraList[0].price?.toLocaleString() : '';
  const hasEtc = simulationManagerStore.nowSelectEtc !== '100003';
  const etcPrice = simulationManagerStore.etcList[0] ? simulationManagerStore.etcList[0].price?.toLocaleString() : '';
  const hasFloorMat = simulationManagerStore.nowSelectFloorMat !== '100004';
  const floorMatPrice = simulationManagerStore.floorMatList[0] ? simulationManagerStore.floorMatList[0].price?.toLocaleString() : '';
  const hasWantNo = simulationManagerStore.nowSelectWantNo !== '100005';
  const wantNoPrice = simulationManagerStore.wantNoList[0] ? simulationManagerStore.wantNoList[0].price?.toLocaleString() : '';
  const hasDoorVisor = simulationManagerStore.nowSelectDoorVisor !== '100006';
  const doorVisorPrice = simulationManagerStore.doorVisorList[0] ? simulationManagerStore.doorVisorList[0].price?.toLocaleString() : '';
  const hasWantCar = simulationManagerStore.nowSelectWantCar !== 'no';
  const wantCarPrice = simulationManagerStore.wantCarPrice ? simulationManagerStore.wantCarPrice.toLocaleString() : '';
  const hasOption = hasCarNavi || hasBackCamera || hasEtc || hasFloorMat || hasWantNo || hasDoorVisor || hasWantCar;

  // メンテプラン
  const maintenanceName = NEWCAR_CONST.MAINTENANCE.PLAN_SHORT_NAME(simulationManagerStore.nowPickedMaintenancePlan) || 'なし';
  const maintenanceColor = () => {
    switch (simulationManagerStore.nowPickedMaintenancePlan) {
      case NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM:
        return '#2c5488';
      case NEWCAR_CONST.MAINTENANCE.PLAN.GOLD:
        return '#dfaf2e';
      case NEWCAR_CONST.MAINTENANCE.PLAN.SILVER:
        return '#b2b2b2';
      default:
        return '';
    }
  };
  const maintenancePrice = () => {
    const list = simulationManagerStore.maintenancePlanList;
    const targetId = simulationManagerStore.nowPickedMaintenancePlan;
    const maintenancePlan = list.find((item) => (item.id === targetId));
    return maintenancePlan ? maintenancePlan.price.toLocaleString() : '';
  };

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isShow) {
      setStyle({});
    } else {
      // コンテンツの高さが一番高いところで揃ってしまうので非表示の時は高さを0にする
      setTimeout(() => setStyle({ height: '0px' }), 400);
    }
  }, [isShow]);

  const goToEntryForm = () => {
    if (simulationManagerStore.nowSelectGrade) {
      simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
    } else {
      simulationManagerStore.nowCarToUndefined();
      simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP);
    }
    navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
  };

  return (
    <div className={`${styles.content}`} style={style} data-type="simulation-result">
      <div className="has-background-white is-padding-5 is-margin-bottom-3">
        <h3 className="has-text-weight-bold">月額料金</h3>
        <p>
          {carName}
          <small className="is-padding-left-5">{gradeSub}</small>
        </p>
        <div className="columns is-mobile is-padding-top-5 is-margin-bottom-3">
          <div className="column car-image-size is-margin-right-3 is-3">
            <img src={thumbnailUrl} alt="車画像" height="64" />
          </div>
          <div className={`${styles.resultPrice} column`}>
            <div className="is-flex">
              <p className={`${styles.item} ${styles.adjust} is-size-6 is-padding-right-3`}>通常価格</p>
              <s>
                {nomalPrice}
                <small>円(税込)</small>
              </s>
            </div>
            <div className={`${styles.priceTrico}`} />
            <div className="is-flex">
              <strong className={`${styles.item} has-text-primary is-size-6 is-padding-right-3`}>
                ネット割
                <br />
                適用後価格
              </strong>
              <span>
                <strong className={`${styles.discountPrice} has-text-primary`}>{discountPrice}</strong>
                <small className="has-text-black-ter has-text-weight-light">円(税込)</small>
              </span>
            </div>
          </div>
        </div>
        <p className={`${styles.annotation} ${styles.asterisk} is-margin-bottom-6`}>写真は見本です。シミュレーションで選択したカラーやグレードと異なっていることがあります。</p>

        {/* CTA */}
        <div className="is-padding-bottom-3">
          <button
            type="button"
            className={`${styles.resultCta} ${styles.isBlue} button is-large is-full-width is-size-3 has-text-weight-semibold has-text-white`}
            data-gtm="gtm-newcar-simulator-modalResult-FVnextBtn"
            onClick={goToEntryForm}
          >
            まずはお試しで審査に申込む
            <span className="icon-arrow-right-big is-margin-right-2" />
          </button>
          <p className="is-size-6 is-margin-top-1 is-valign-center">※審査申込みは契約ではありません</p>
        </div>
      </div>

      <div className={`${styles.resultDetail} is-padding-5`}>
        <h3 className="has-text-weight-bold is-margin-bottom-5">シミュレーション内容</h3>
        <dl>
          <div className="is-padding-left-3 is-border-bottom-line is-padding-bottom-3">
            <dt className="has-text-weight-semibold">ご利用期間</dt>
            <dd>
              {simulationManagerStore.nowSelectTerm}
              年
            </dd>
          </div>
          <div className="is-padding-left-3 is-margin-top-4 is-border-bottom-line is-padding-bottom-3">
            <dt className="has-text-weight-semibold">グレード</dt>
            <dd>{gradeName}</dd>
          </div>
          <div className="is-padding-left-3 is-margin-top-4 is-border-bottom-line is-padding-bottom-3">
            <dt className="has-text-weight-semibold">カラー</dt>
            <dd className="is-flex has_vertical_centered">
              {bodyColorName ? (
                <>
                  <ColorSquare width={15} height={15} colorCode1={colorCode1} colorCode2={colorCode2} />
                  <span className="is-padding-left-2">{bodyColorName}</span>
                </>
              ) : (
                <>未選択（ご商談時に選べます）</>
              )}
            </dd>
          </div>
          <div className={`${styles.option} is-padding-left-3 is-margin-top-4 is-border-bottom-line is-padding-bottom-3`}>
            <dt className="has-text-weight-semibold">オプション装備</dt>
            {hasCarNavi && (
              <div className="columns is-mobile">
                <dd className="column">カーナビ</dd>
                <dd className="column is-narrow">{`+${carNaviPrice}円`}</dd>
              </div>
            )}
            {hasBackCamera && (
              <div className="columns is-mobile">
                <dd className="column">バックカメラ</dd>
                <dd className="column is-narrow">{`+${backCameraPrice}円`}</dd>
              </div>
            )}
            {hasEtc && (
              <div className="columns is-mobile">
                <dd className="column">ETC</dd>
                <dd className="column is-narrow">
                  {`+${etcPrice}円`}
                  {' '}
                </dd>
              </div>
            )}
            {hasFloorMat && (
              <div className="columns is-mobile">
                <dd className="column">フロアマット</dd>
                <dd className="column is-narrow">{`+${floorMatPrice}円`}</dd>
              </div>
            )}
            {hasWantNo && (
              <div className="columns is-mobile">
                <dd className="column">希望ナンバー</dd>
                <dd className="column is-narrow">{`+${wantNoPrice}円`}</dd>
              </div>
            )}
            {hasDoorVisor && (
              <div className="columns is-mobile">
                <dd className="column">ドアバイザー</dd>
                <dd className="column is-narrow">{`+${doorVisorPrice}円`}</dd>
              </div>
            )}
            {/* もらえるオプションはリース年数変えると外れることがあるため条件を厳しく */}
            {(hasWantCar && wantCarPrice) && (
              <div className="columns is-mobile">
                <dd className="column">もらえるオプション</dd>
                <dd className="column is-narrow">{`+${wantCarPrice}円`}</dd>
              </div>
            )}
            {!hasOption && (
              <div className="columns is-mobile">なし</div>
            )}
          </div>
          <div className="is-padding-left-3 is-margin-top-4">
            <dt className="has-text-weight-semibold">メンテナンスプラン</dt>
            <div className="columns is-mobile">
              <dd className="column is-flex has_vertical_centered">
                {maintenanceColor() && (
                  <svg viewBox="0 0 15 15" width="15" height="15">
                    <rect fill={maintenanceColor()} x="0" y="0" width="15" height="15" stroke="#aaaaaa" strokeWidth="0.5px" />
                  </svg>
                )}
                <span className="is-padding-left-2">{maintenanceName}</span>
              </dd>
              {maintenancePrice() && (
                <dd className="column is-narrow">{`+${maintenancePrice()}円`}</dd>
              )}
            </div>
          </div>
        </dl>
      </div>

      <div className="is-padding-5 is-margin-bottom-6">
        <p className="is-size-5">【月額料金に含まれるもの】</p>
        <p className="is-size-5">
          車両本体料、自賠責保険料、自動車税、重量税、印紙代、環境性能割、新車登録手数料、仲介手数料、お客様サポート料
          <br />
          ※月額料金に含まれない車検点検費用は、メンテナンスプランご加入で定額支払いにできます。
        </p>
      </div>
    </div>
  );
};

export default ModalResult;
