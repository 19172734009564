import React, { FC } from 'react';
import Card from '@/newcar/ui/detail/molecule/Card';
import iconTeigaku from '@/newcar/ui/detail/img/icon-teigaku.svg';
import iconSoukou from '@/newcar/ui/detail/img/icon-soukou.svg';
import iconHiyou from '@/newcar/ui/detail/img/icon-hiyou.svg';
import iconOption from '@/newcar/ui/detail/img/icon-option.svg';
import * as styles from '@/newcar/ui/detail/organism/Reason.module.styl';

const Reason: FC = () => (
  <section className="container">
    <Card>
      <h2 className="title is-3 has-text-centered">定額カルモくんが人気の理由</h2>
      <div className={`columns ${styles.reason}`}>
        {/* 月額完全定額制 */}
        <div className="column">
          <div className={`columns is-mobile is-vcentered is-padding-3 ${styles.reasonBorder} ${styles.isFlexDirectionColumnReverse}`}>
            <div className={`column is-narrow is-margin-right-3 ${styles.reasonNumber}`}>
              <div className={`has-text-centered has-text-white ${styles.hasTextDecoration}`}>
                <span className="has-text-weight-semibold">1</span>
              </div>
            </div>
            <div className={`column is-padding-3 has-text-centered-tablet ${styles.isMarginLeft3Mobile}`}>
              <p className={`is-size-5 has-text-weight-semibold ${styles.reasonLead}`}>
                毎月ずーっと定額！
                <br />
                だから家計管理も安心
              </p>
              <p className="is-size-6 has-text-grey">
                頭金・ボーナス払いなし、
                <br />
                ネット割で最大49,500円お得
              </p>
            </div>
            <div className="column is-narrow is-margin-bottom-2">
              <img
                className={`${styles.reasonImg}`}
                src={iconTeigaku}
                width="120"
                height="120"
                alt="毎月ずーっと定額"
                decoding="async"
              />
            </div>
          </div>
        </div>
        {/* 走行距離無制限 */}
        <div className="column">
          <div className={`columns is-mobile is-vcentered is-padding-3 ${styles.reasonBorder} ${styles.isFlexDirectionColumnReverse}`}>
            <div className={`column is-narrow is-margin-right-3 ${styles.reasonNumber}`}>
              <div className={`has-text-centered has-text-white ${styles.hasTextDecoration}`}>
                <span className="has-text-weight-semibold">2</span>
              </div>
            </div>
            <div className={`column is-padding-3 has-text-centered-tablet ${styles.isMarginLeft3Mobile}`}>
              <p className={`is-size-5 has-text-weight-semibold ${styles.reasonLead}`}>走行距離が無制限！</p>
              <p className="is-size-6 has-text-grey">
                7年以上のご契約の場合、
                <br />
                走行距離は無制限です
              </p>
            </div>
            <div className="column is-narrow is-margin-bottom-2">
              <img
                className={`${styles.reasonImg}`}
                src={iconSoukou}
                width="120"
                height="120"
                alt="走行距離が無制限"
                decoding="async"
              />
            </div>
          </div>
        </div>
        {/* 税金コミ */}
        <div className="column">
          <div className={`columns is-mobile is-vcentered is-padding-3 ${styles.reasonBorder} ${styles.isFlexDirectionColumnReverse}`}>
            <div className={`column is-narrow is-margin-right-3 ${styles.reasonNumber}`}>
              <div className={`has-text-centered has-text-white ${styles.hasTextDecoration}`}>
                <span className="has-text-weight-semibold">3</span>
              </div>
            </div>
            <div className={`column is-padding-3 has-text-centered-tablet ${styles.isMarginLeft3Mobile}`}>
              <p className={`is-size-5 has-text-weight-semibold ${styles.reasonLead}`}>税金・諸費用コミコミの料金</p>
              <p className="is-size-6 has-text-grey">環境性能割・登録諸費用・自動車税(種別割)・重量税・自賠責・印紙代全てコミ！</p>
            </div>
            <div className="column is-narrow is-margin-bottom-2">
              <img
                className={`${styles.reasonImg}`}
                src={iconHiyou}
                width="120"
                height="120"
                alt="環境性能割・登録諸費用・自動車税(種別割)・重量税・自賠責・印紙代全てコミ！"
                decoding="async"
              />
            </div>
          </div>
        </div>
        {/* もらえる/メンテプラン */}
        <div className="column">
          <div className={`columns is-mobile is-vcentered is-padding-3 ${styles.reasonBorder} ${styles.isFlexDirectionColumnReverse}`}>
            <div className={`column is-narrow is-margin-right-3 ${styles.reasonNumber}`}>
              <div className={`has-text-centered has-text-white ${styles.hasTextDecoration}`}>
                <span className="has-text-weight-semibold">4</span>
              </div>
            </div>
            <div className={`column is-padding-3 has-text-centered-tablet ${styles.isMarginLeft3Mobile}`}>
              <p className={`is-size-5 has-text-weight-semibold ${styles.reasonLead}`}>
                もらえるオプションや
                <br />
                メンテナンスプランをご用意
              </p>
              <p className="is-size-6 has-text-grey">もらえるオプションは、契約満了後にクルマがもらえます</p>
            </div>
            <div className="column is-narrow is-margin-bottom-2">
              <img
                className={`${styles.reasonImg}`}
                src={iconOption}
                width="120"
                height="120"
                alt="もらえるオプションメンテナンスプラン"
                decoding="async"
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  </section>
);

export default Reason;
