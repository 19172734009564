import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Show from '@/newcar/ui/molecule/Show';

type Props = {
  items: {
    name: string;
    item: string;
  }[];
};

/**
 * パンくずの構造化マークアップ
 */
const BreadcrumbSchemaMarkup: FC<Props> = ({ items }) => (
  <Show when={items.length > 0}>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: items.map(({ name, item }, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name,
            item,
          })),
        })}
      </script>
    </Helmet>
  </Show>
);

export default BreadcrumbSchemaMarkup;
