/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  useContext,
} from 'react';
import NEWCAR_CONST from '@/newcar/util/const';
import { Observer } from 'mobx-react-lite';
import * as styles from '@/newcar/ui/detail/organism/PlanDialog.module.styl';
import CARMO_CONST from '@/common/util/const';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';

interface ModalOptionProps {
  selectedPlanID: NEWCAR_CONST.SIMULATION_PLAN.ID;
  hasWantCar: boolean;
}

const ModalOption: FC<ModalOptionProps> = ({ selectedPlanID, hasWantCar }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  // もらえるオプションの対象かどうか
  const wantCarTarget = (): boolean => {
    let selectTerm: NEWCAR_CONST.TERM.ID;
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      selectTerm = (simulationManagerStore.nowSimpleSelection.term)
        ? simulationManagerStore.nowSimpleSelection.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      selectTerm = (simulationManagerStore.nowRecommendSelection.term)
        ? simulationManagerStore.nowRecommendSelection.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      selectTerm = (simulationManagerStore.nowRichSelection.term)
        ? simulationManagerStore.nowRichSelection.term : NEWCAR_CONST.TERM.ID.YEAR_11;
    } else {
      // オリジナルプラン
      selectTerm = simulationManagerStore.nowSelectTerm;
    }
    return ((
      selectTerm === NEWCAR_CONST.TERM.ID.YEAR_11
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_10
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_9
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_8
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_7
    ) && (
      hasWantCar
    ));
  };

  const changeCarNavi = (id: string) => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleCarNavi(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendCarNavi(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichCarNavi(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeCarNavi(id);
    }
  };

  const changeETC = (id: string) => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleETC(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendETC(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichETC(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeETC(id);
    }
  };

  const changeBackCamera = (id: string) => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleBackCamera(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendBackCamera(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichBackCamera(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeBackCamera(id);
    }
  };

  const changeFloorMat = (id: string) => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleFloorMat(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendFloorMat(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichFloorMat(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeFloorMat(id);
    }
  };

  const changeDoorVisor = (id: string) => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleDoorVisor(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendDoorVisor(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichDoorVisor(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeDoorVisor(id);
    }
  };

  const changeWantNo = (id: string) => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleWantNo(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendWantNo(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichWantNo(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeWantNo(id);
    }
  };

  const changeWantCar = (id: string) => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleWantCar(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendWantCar(id);
    } else if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichWantCar(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeWantCar(id);
    }
  };

  interface ListProps {
    id: string,
    notid: string,
    isStandard: boolean,
    optionInfoId: number,
    thumbnailFileName?: string,
    name?: string,
    description?: string,
    optionType?: string,
    price?: number,
    isChecked?: boolean | undefined
  }

  const carNaviList = (): Array<ListProps> => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansCarNaviList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansCarNaviList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansCarNaviList;
    }
    // オリジナルプラン
    return simulationManagerStore.carNaviList;
  };

  const etcList = (): Array<ListProps> => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansEtcList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansEtcList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansEtcList;
    }
    // オリジナルプラン
    return simulationManagerStore.etcList;
  };

  const backCameraList = (): Array<ListProps> => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansbackCameraList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansbackCameraList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansbackCameraList;
    }
    // オリジナルプラン
    return simulationManagerStore.backCameraList;
  };

  const floorMatList = () : Array<ListProps> => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansFloorMatList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansFloorMatList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansFloorMatList;
    }
    // オリジナルプラン
    return simulationManagerStore.floorMatList;
  };

  const doorVisorList = (): Array<ListProps> => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansDoorVisorList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansDoorVisorList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansDoorVisorList;
    }
    // オリジナルプラン
    return simulationManagerStore.doorVisorList;
  };

  const wantNoList = (): Array<ListProps> => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansWantNoList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansWantNoList;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansWantNoList;
    }
    // オリジナルプラン
    return simulationManagerStore.wantNoList;
  };

  const wantCarPrice = ():number => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansWantCarPrice;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansWantCarPrice;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansWantCarPrice;
    }
    // オリジナルプラン
    return simulationManagerStore.wantCarPrice;
  };

  const nowSelectWantCar = (): string => {
    if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.nowSelectSimpleWantCar;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.nowSelectRecommendWantCar;
    } if (selectedPlanID == NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.nowSelectRichWantCar;
    }
    // オリジナルプラン
    return simulationManagerStore.nowSelectWantCar;
  };

  // オプションごとの配列を受け取ってチェックボックスを生成する
  const checkBox = (list: any[], key: string, onChange: Function) => list.map((value, index) => (
    <div className={`${styles.checkboxRow} is-padding-1`} key={index.toString()}>
      <input
        type="checkbox"
        name={key}
        id={`${key}-${value.id}`}
        checked={value.isChecked}
        disabled={value.isStandard}
        onChange={(event) => onChange(event.target.checked ? value.id : value.notid)}
      />
      <label htmlFor={`${key}-${value.id}`} className="box is-padding-5">
        <span>{value.optionType}</span>
        {value.isStandard ? (
          <span>標準装備</span>
        ) : (
          <span className="is-size-4 has-text-weight-bold">
            {`+${CARMO_CONST.COMMA_FORMAT(value.price)}`}
            <span className="is-size-6">円/月</span>
          </span>
        )}
      </label>
    </div>
  ));

  return (
    <Observer>
      {() => (
        <>
          <div className={`${styles.content} is-padding-5`}>
            <h2 className="has-text-centered is-margin-bottom-3 is-size-3 has-text-weight-light">オプションをえらぶ</h2>
            <div className={styles.checkboxContainer}>
              {/* カーナビ */}
              {checkBox(carNaviList(), 'car-navi', (id: string) => changeCarNavi(id))}

              {/* ETC */}
              {checkBox(etcList(), 'etc', (id: string) => changeETC(id))}

              {/* バックカメラ */}
              {checkBox(backCameraList(), 'back-camera', (id: string) => changeBackCamera(id))}

              {/* フロアマット */}
              {checkBox(floorMatList(), 'floor-mat', (id: string) => changeFloorMat(id))}

              {/* ドアバイザー */}
              {checkBox(doorVisorList(), 'door-visor', (id: string) => changeDoorVisor(id))}

              {/* 希望ナンバー */}
              {checkBox(wantNoList(), 'want-no', (id: string) => changeWantNo(id))}

              {/* もらえるプラン */}
              {wantCarTarget() && checkBox(
                [{
                  optionType: 'もらえるオプション※1',
                  price: wantCarPrice(),
                  id: 'yes',
                  notid: 'no',
                  isChecked: nowSelectWantCar() === 'yes',
                }],
                'want-car',
                (id: string) => changeWantCar(id),
              )}
            </div>

            <ul className={styles.optionNoticeContainer}>
              <li>※1「もらえるオプション」はリース期間満了後にクルマがもらえるオプションです</li>
              <li>※メーカーオプションなど、上記以外のオプションも搭載可能ですので商談時にご相談ください</li>
              <li>※上記オプションは純正品やグレード専用品でない場合もございます。ご指定がございましたら一度ご確認しますので、お問い合わせくださいませ。お申し込み後の変更も承っております。</li>
              <li>※表示金額は月額税込の金額です</li>
            </ul>
          </div>
        </>
      )}
    </Observer>
  );
};

export default ModalOption;
