/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import React, {
  FC,
} from 'react';
import CarDigest from '@/common/core/model/carDigest';
import Card from '@/newcar/ui/detail/molecule/Card';
import tripleCrown from '@/newcar/ui/detail/img/triple_crown.svg';
import tripleCrownCarmoLogo from '@/newcar/ui/detail/img/triple_crown_carmo_logo.jpg';
import reasonRecommend from '@/newcar/ui/detail/img/reasonRecommend.svg';
import reasonSupport from '@/newcar/ui/detail/img/reasonSupport.svg';
import reasonCost from '@/newcar/ui/detail/img/reasonCost.svg';
import reasonRecommendSp from '@/newcar/ui/detail/img/reasonRecommendSp.svg';
import reasonSupportSp from '@/newcar/ui/detail/img/reasonSupportSp.svg';
import reasonCostSp from '@/newcar/ui/detail/img/reasonCostSp.svg';
import reasonRecommendWebp from '@/newcar/ui/detail/img/reasonRecommend.webp';
import reasonSupportWebp from '@/newcar/ui/detail/img/reasonSupport.webp';
import reasonCostWebp from '@/newcar/ui/detail/img/reasonCost.webp';
import reasonRecommendWebpSp from '@/newcar/ui/detail/img/reasonRecommendSp.webp';
import reasonSupportWebpSp from '@/newcar/ui/detail/img/reasonSupportSp.webp';
import reasonCostWebpSp from '@/newcar/ui/detail/img/reasonCostSp.webp';
import CTA_PC from '@/newcar/ui/detail/molecule/CTA_PC';
import CTA_SP from '@/newcar/ui/detail/molecule/CTA_SP';
import * as styles from '@/newcar/ui/detail/organism/Charm.module.styl';

type PickDigest = Pick<CarDigest, 'thumbnailUrl'>;
type IsNowSale = { isNowSale: boolean };
type CharmProps = PickDigest & IsNowSale;

const Charm: FC<CharmProps> = ({
  thumbnailUrl,
  isNowSale,
}) => (
  <section className="container">
    <Card className={`${styles.cardPadding}`}>
      <h2 className="title is-3 has-text-centered is-margin-bottom-5">国内No.1カーリース</h2>
      <div className="is-margin-bottom-6 has-text-centered">
        <img
          src={tripleCrown}
          className={styles.sankanDisplay}
          width="295"
          height="78"
          alt="三冠獲得"
          decoding="async"
        />
      </div>
      <div className="is-margin-bottom-6 has-text-centered">
        <img src={tripleCrownCarmoLogo} alt="お得にマイカー 定額カルモくん" width="164" />
      </div>
      <div className={`columns ${styles.sankanArea}`}>
        <picture className={`column has-text-centered is-margin-bottom-3 ${styles.sankanItem}`}>
          <source srcSet={reasonRecommendWebpSp} type="image/webp" media="(max-width: 768px)" />
          <source srcSet={reasonRecommendSp} media="(max-width: 768px)" />
          <source srcSet={reasonRecommendWebp} type="image/webp" />
          <source srcSet={reasonRecommend} />
          <img src={reasonRecommend} className={styles.sankanImage} alt="カーリース利用経験者が選ぶ、おすすめしたいと思うカーリース No.1" loading="lazy" decoding="async" width="314" height="78" />
        </picture>
        <picture className={`column has-text-centered is-margin-bottom-3 ${styles.sankanItem}`}>
          <source srcSet={reasonSupportWebpSp} type="image/webp" media="(max-width: 768px)" />
          <source srcSet={reasonSupportSp} media="(max-width: 768px)" />
          <source srcSet={reasonSupportWebp} type="image/webp" />
          <source srcSet={reasonSupport} />
          <img src={reasonSupport} className={styles.sankanImage} alt="カーディーラー勤務者が選ぶサポートが充実していると思うカーリース No.1" loading="lazy" decoding="async" width="314" height="78" />
        </picture>
        <picture className={`column has-text-centered is-margin-bottom-3 ${styles.sankanItem}`}>
          <source srcSet={reasonCostWebpSp} type="image/webp" media="(max-width: 768px)" />
          <source srcSet={reasonCostSp} media="(max-width: 768px)" />
          <source srcSet={reasonCostWebp} type="image/webp" />
          <source srcSet={reasonCost} />
          <img src={reasonCost} className={styles.sankanImage} alt="ファイナンシャルプランナーが選ぶ、コストパフォーマンスが高いと思うカーリースNo.1" loading="lazy" decoding="async" width="314" height="78" />
        </picture>
      </div>
      <p className="has-text-grey is-size-7 is-padding-right-4 is-padding-left-4 is-margin-bottom-4">
        2024年1月期_ブランドのイメージ調査（調査1～3） 調査機関：日本マーケティングリサーチ機構 調査期間：2023年12月14日～2024年1月9日 n数：227（※調査1）、103（※調査2）、177（※調査3）/調査方法：Webアンケート 調査対象者：https://jmro.co.jp/r01525/ 備考：本調査は個人のブランドに対するイメージを元にアンケートを実施し集計しております。/本ブランドの利用有無は聴取しておりません。/効果効能等や優位性を保証するものではございません。/競合2位との差は5％以上。
      </p>

      {isNowSale && (
        <>
          {/* CTA部分_SP版 */}
          <div className="is-hidden-desktop">
            <CTA_SP
              thumbnailUrl={thumbnailUrl}
            />
          </div>

          {/* CTA部分_PC版 */}
          <div className="is-hidden-mobile is-hidden-tablet-only">
            <CTA_PC
              thumbnailUrl={thumbnailUrl}
            />
          </div>
        </>
      )}
    </Card>
  </section>
);
export default Charm;
