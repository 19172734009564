import React, {
  FC, useContext, useState,
} from 'react';
import { navigate } from 'gatsby';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import Card from '@/newcar/ui/detail/molecule/Card';
import ButtonRequestAssessmentSP from '@/newcar/ui/detail/molecule/ButtonRequestAssessmentSP';
import arrow from '@/newcar/ui/common/img/object/icon/arrow-red.svg';
import shopManager from '@/newcar/ui/detail/img/img-shop-manager.png';
import * as styles from '@/newcar/ui/detail/organism/Trial.module.styl';

const Trial: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  // プラン詳細を見るで使用する計算結果
  const afterPrice = () => simulationManagerStore.nowRecommendSPSelection?.price?.toLocaleString() || '';
  const planName = () => simulationManagerStore.nowRecommendSPSelection.orderName || '';
  const planOption = () => simulationManagerStore.nowRecommendSPSelection.option || '';
  const planYear = () => simulationManagerStore.nowRecommendSPSelection.term || '';
  const planMonth = () => Number(simulationManagerStore.nowRecommendSPSelection.term) * 12 || '';

  // 相談申し込みCTA
  const goToTrialEntryForm = () => {
    simulationManagerStore.nowCarToUndefined();
    simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.TRIAL);
    navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
  };

  return (
    <>
      {/* SP */}
      <section className="container is-hidden-desktop">
        <Card>
          <h2 className="title is-3 has-text-centered">車選びでお困りではありませんか？</h2>
          <div className="is-margin-bottom-4">
            <img src={shopManager} alt="自動車整備士資格を持つ、カルモくん店長・若林" />
            <span className="is-block is-size-7 has-text-right">自動車整備士資格を持つ、カルモくん店長・若林</span>
          </div>
          <div className="is-size-5 is-margin-bottom-5">
            <p className="is-padding-right-4 is-padding-left-4">
              車のプロがあなたの車選びを無料サポートします。
              審査後のキャンセル・プラン変更は可能ですので、まずはお試し審査にお申込みいただき、
              お車選びのサポートを受けていただく流れがおすすめです。
            </p>
          </div>
          {/* 申し込みボタン */}
          <div className="has-text-centered">
            <div className="has-text-center is-margin-top-5 balloon-campaign-parent">
              <span className={`is-size-6 is-relative ${styles.balloonCampaign}`}>無料！5分でかんたんお申し込み</span>
            </div>
            <ButtonRequestAssessmentSP
              className={`${styles.isBlue} ${styles.isShaped} button has-head-copy `}
              buttonText="まずはお試しで審査に申込む"
              handlerOnClick={goToTrialEntryForm}
            />
          </div>

          {/* プラン詳細 */}
          <div
            className="columns is-mobile is-multiline is-centered is-vcentered"
            onClick={() => setIsOpen(!isOpen)}
            data-gtm="gtm-newcar-detail-fvPlanAccordion"
            role="button"
            tabIndex={0}
          >
            <div className={`column is-narrow is-size-6 is-padding-right-2 ${styles.isUnderline}`}>プラン詳細を見る</div>
            <span className={`${styles.plusBtn} ${isOpen && styles.active}`} />
          </div>
          {isOpen && (
            <div className={`columns is-desktop is-multiline is-centered is-vcentered is-size-6 is-padding-5 is-margin-bottom-5 ${styles.isBorderGreyLighter}`}>
              <div className="column is-padding-bottom-3">
                <p className="has-text-weight-semibold">グレード</p>
                <p className="has-text-grey">{planName()}</p>
              </div>
              <div className="column is-padding-bottom-3">
                <p className="has-text-weight-semibold">オプション装備</p>
                <p className="has-text-grey">{planOption()}</p>
              </div>
              <div className="column is-padding-bottom-3">
                <p className="has-text-weight-semibold">賃貸期間</p>
                <p className="has-text-grey">
                  {planMonth() ? `${planMonth()}ヶ月 ` : ''}
                  {planYear()}
                  年
                </p>
              </div>
              <div className="column is-padding-bottom-3">
                <p className="has-text-weight-semibold">月額料金</p>
                <p className="has-text-grey">
                  {afterPrice()}
                  円/月（税込）
                </p>
              </div>
            </div>
          )}
        </Card>
      </section>

      {/* PC */}
      <section className="container is-hidden-mobile is-hidden-tablet-only">
        <Card>
          <h2 className="title is-3 has-text-centered">車選びでお困りではありませんか？</h2>
          <div className="is-size-5 is-margin-bottom-5 has-text-centered">
            <p>車のプロがあなたの車選びを無料サポートします。</p>
            <p>審査後のキャンセル・プラン変更は可能ですので、下記よりお気軽にお申し込みください。</p>
          </div>
          <div className="has-text-centered">
            <button
              type="button"
              className={`button is-large is-white is-fixed-width-button has-text-info ${styles.consultationApplicationButton}`}
              onClick={goToTrialEntryForm}
              data-gtm="gtm-newcardetail-ConsultationApplication"
            >
              <div className="has-text-centered">
                <strong className="is-size-4 has-text-primary">とりあえず相談申込に進む</strong>
                <img src={arrow} alt="矢印" className={`${styles.arrowRed}`} />
              </div>
            </button>
          </div>
        </Card>
      </section>
    </>
  );
};
export default Trial;
