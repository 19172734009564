/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, {
  FC, useState, ReactNode,
} from 'react';
import FAQSchemaMarkup from '@/newcar/ui/docs/faq/organism/FAQSchemaMarkup';
import Card from '@/newcar/ui/detail/molecule/Card';
import * as styles from '@/newcar/ui/detail/organism/FAQ.module.styl';

const FAQ: FC = () => {
  const [showAnswer, setShowAnswer] = useState<boolean[]>([]);

  const accordion = (index: number, question: ReactNode, answer: ReactNode) => {
    if (showAnswer.length === index) {
      setShowAnswer([...showAnswer, false]);
    }

    return (
      <div className="is-size-5 is-border-line is-margin-bottom-5">
        <div className="accordion is-relative">
          <div className={`columns is-mobile is-relative ${styles.faqQuestion}`} onClick={() => setShowAnswer(showAnswer.map((val, i) => (i === index ? !val : val)))}>
            <div className="column">
              <div className="is-size-5 has-text-weight-semibold">
                {question}
              </div>
            </div>
            <div className="column is-narrow icon-area">
              <i className={`icon is-small has-background-primary accordion-icon" ${showAnswer[index] ? 'icon-minus' : 'icon-plus'}`} />
            </div>
          </div>
        </div>
        <div className={`${styles.panel} ${showAnswer[index] && styles.isOpen}`}>
          <p className="is-size-5 is-padding-3">
            {answer}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* 構造化マークアップ */}
      <FAQSchemaMarkup
        items={[
          {
            question: 'リース料金には何が含まれていますか',
            answer: '<p>車両代に加えて自動車税や重量税、自賠責保険などの法定費用などがリース料金に含まれています。</p><p>加えて、車検点検費用もカバーする<a href=https://carmo-kun.jp/docs/maintenance/>メンテナンスプラン</a>もオプションで追加出来ます。</p><p>自動車購入の際には自己負担になる部分が含まれているため、車に関する出費がフラットになり家計の管理がラクになります。</p><p>ぜひご検討くださいませ。</p>',
          },
          {
            question: '契約年数は決まっていますか',
            answer: '1年を最短とし、11年まで1年単位でお選びいただけます。',
          },
          {
            question: '走行距離に制限はありますか',
            answer: '<p>7年以上でご契約される場合、走行距離に制限はございません。</p><p>また、契約満了時に乗っていた車を追加料金無しでもらうことが可能になる「もらえるオプション」もご用意しており、カーリースでもマイカー感覚でご利用いただけます。</p><p>詳しくは<a href=https://carmo-kun.jp/docs/moraeru/>こちら</a>をご覧ください。</p>',
          },
          {
            question: '「もらえるオプション」とはなんですか',
            answer: '<p>契約期間満了時に追加精算ナシで乗っていた車両をもらえるオプションです。7年以上の新車カーリース契約でご加入いただけます。<p>もらえるオプションについて詳しくは<a href=https://carmo-kun.jp/docs/moraeru/>こちら</p>',
          },
          {
            question: '審査に通るか不安です',
            answer: '【審査に通るか不安です】・できるだけ安い車種やプランで申し込むのがおすすめです。定額カルモくんでは、業界最安水準でお車を豊富に揃えています。・審査の基準が異なりますので、カーローンや他社のリース審査に落ちた方でも定額カルモくんをご利用できる可能性があります。【パート・アルバイトでも審査に通りますか】パート・アルバイト勤務の方で定額カルモくんをご利用されているお客様も多くいらっしゃいます。どうぞお気軽にお申込みください。',
          },
        ]}
      />

      <section className="container">
        <Card>
          <h2 className="title is-3 has-text-centered">よくあるご質問</h2>
          <div className="columns is-multiline is-centered is-vcentered">
            <div className={`column is-full is-padding-3 ${styles.pcMaxwidth}`}>
              {/* QA1 */}
              {accordion(
                0,
                <>リース料金には何が含まれていますか</>,
                <>
                  車両代に加えて自動車税や重量税、自賠責保険などの法定費用などがリース料金に含まれています。
                  <br />
                  加えて、車検点検費用もカバーする
                  <a href="/docs/maintenance/" className="has-text-primary">メンテナンスプラン</a>
                  もオプションで追加出来ます。
                  <br />
                  自動車購入の際には自己負担になる部分が含まれているため、車に関する出費がフラットになり家計の管理がラクになります。
                  <br />
                  ぜひご検討くださいませ。
                </>,
              )}

              {/* QA2(旧QA4) */}
              {accordion(
                1,
                <>「もらえるオプション」とはなんですか</>,
                <>
                  契約期間満了時に追加精算ナシで乗っていた車両をもらえるオプションです。7年以上の新車カーリース契約でご加入いただけます。
                  <br />
                  もらえるオプションについて詳しくは
                  <a href="/docs/moraeru/" className="has-text-primary">こちら</a>
                </>,
              )}

              {/* QA3(新規追加) */}
              {accordion(
                2,
                <>審査に通るか不安です</>,
                <>
                  <p className="is-margin-bottom-5">
                    【審査に通るか不安です】
                    <br />
                    ・できるだけ安い車種やプランで申し込むのがおすすめです。定額カルモくんでは、業界最安水準でお車を豊富に揃えています。
                    <br />
                    ・審査の基準が異なりますので、カーローンや他社のリース審査に落ちた方でも定額カルモくんをご利用できる可能性があります。
                  </p>
                  <p>
                    【パート・アルバイトでも審査に通りますか】
                    <br />
                    パート・アルバイト勤務の方で定額カルモくんをご利用されているお客様も多くいらっしゃいます。どうぞお気軽にお申込みください。
                  </p>
                </>,
              )}

              {/* QA4(旧QA2) */}
              {accordion(
                3,
                <>契約年数は決まっていますか</>,
                <>
                  1年を最短とし、11年まで1年単位でお選びいただけます。
                </>,
              )}

              {/* QA5(旧QA3) */}
              {accordion(
                4,
                <>走行距離に制限はありますか</>,
                <>
                  7年以上でご契約される場合、走行距離に制限はございません。
                  <br />
                  <br />
                  また、契約満了時に乗っていた車を追加料金無しでもらうことが可能になる「もらえるオプション」もご用意しており、カーリースでもマイカー感覚でご利用いただけます。
                  <br />
                  <br />
                  詳しくは
                  <a href="/docs/moraeru/" className="has-text-primary">こちら</a>
                  をご覧ください。
                </>,
              )}
            </div>

            <div className="column is-full has-text-centered">
              <a href="/docs/faq/" className="button is-large is-fixed-width-button">
                <div>もっと見る</div>
              </a>
            </div>
          </div>
        </Card>
      </section>
    </>
  );
};
export default FAQ;
