/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useContext } from 'react';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';

import * as styles from '@/newcar/ui/detail/organism/PlanDialog.module.styl';
import NEWCAR_CONST from '@/newcar/util/const';

interface ModalLeaseProps {
  closeDialog: () => void;
}

const ModalLease: FC<ModalLeaseProps> = ({ closeDialog }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const termList = [
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_11,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_11],
      price: `${simulationManagerStore.term11SelectPrice}円`,

    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_10,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_10],
      price: `${simulationManagerStore.term10SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_9,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_9],
      price: `${simulationManagerStore.term9SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_8,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_8],
      price: `${simulationManagerStore.term8SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_7,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_7],
      price: `${simulationManagerStore.term7SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_6,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_6],
      price: `${simulationManagerStore.term6SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_5,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_5],
      price: `${simulationManagerStore.term5SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_4,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_4],
      price: `${simulationManagerStore.term4SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_3,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_3],
      price: `${simulationManagerStore.term3SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_2,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_2],
      price: `${simulationManagerStore.term2SelectPrice}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_1,
      name: `${NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_1]}(最短)`,
      price: `${simulationManagerStore.term1SelectPrice}円`,
    },
  ];

  const onClick = (id: NEWCAR_CONST.TERM.ID) => {
    const prevTerm = simulationManagerStore.nowSelectTerm;
    if (simulationManagerStore.nowSelectMaintenance
      === NEWCAR_CONST.MAINTENANCE.PLAN_NAME(NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM)) {
      if (id <= 5 && prevTerm >= 6) {
        // 賃貸期間を6年以上から5年以下に変更した時
        simulationManagerStore.setIsShowSimulateAlert(true);
      } else if (prevTerm <= 5 && id >= 6) {
        // 賃貸期間を5年以下から6年以上に変更した時
        simulationManagerStore.setIsShowSimulateAlert(true);
      }
    }
    simulationManagerStore.changeTerm(id);
    closeDialog();
  };

  return (
    <div className={`is-padding-5 ${styles.leaseContents}`}>
      <div className="columns　is-desktop is-multiline is-vcentered is-margin-bottom-6">
        {(() => termList.map((value, index) => (
          <div
            className={`column is-margin-top-3 is-margin-bottom-3 ${styles.leaseBox} ${simulationManagerStore.nowSelectTerm === value.id ? styles.leaseBoxSelected : ''}`}
            key={index.toString()}
            onClick={() => onClick(value.id)}
          >
            <span className="checkbox is-full-width">
              <div className={`is-full-width has-text-centered is-flex ${styles.leaseLabel}`}>
                {value.name}
              </div>
            </span>
          </div>
        )))()}
      </div>
    </div>
  );
};

export default ModalLease;
