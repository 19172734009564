/* eslint-disable @typescript-eslint/naming-convention */
export const PlanTitle = {
  SIMPLE: 'シンプルプラン',
  OSUSUME: 'おすすめプラン',
  YOKUBARI: 'よくばりプラン',
  ORIGINAL: 'オリジナルプラン',
};

export const PlanTitleToId = (title: string): keyof typeof PlanTitle => {
  const [planId] = Object.entries(PlanTitle).find((item) => item[1] === title) ?? [];
  return (planId ?? 'ORIGINAL') as keyof typeof PlanTitle;
};
