// extracted by mini-css-extract-plugin
export const isMarginTop3rem = "PlanPC-module--is-margin-top-3rem--tfjf8";
export const bandSizeSmall = "PlanPC-module--band-size-small--28llC";
export const isPaddingBottomLarge = "PlanPC-module--is-padding-bottom-large--o5Tyx";
export const isFlex = "PlanPC-module--is-flex--td_7S";
export const band = "PlanPC-module--band--1gASp";
export const topBubble = "PlanPC-module--top-bubble--Vd5wY";
export const nextStepBtnWrap = "PlanPC-module--next-step-btn-wrap--2Nck0";
export const nextStepBtn = "PlanPC-module--next-step-btn--2b7zf";
export const customizeBtnWrap = "PlanPC-module--customize-btn-wrap--zgasE";
export const customizeBtn = "PlanPC-module--customize-btn--390LQ";
export const inner = "PlanPC-module--inner--vzCla";
export const detailBtnWrap = "PlanPC-module--detailBtnWrap--2JEg4";
export const arrowBlack = "PlanPC-module--arrow-black--1mkLL";
export const price = "PlanPC-module--price--1jKKr";
export const minHeight3_5rem = "PlanPC-module--min-height-3_5rem--1oDsW";