export type ContractType = 'contact_info' | 'personal_info' | 'review_info' | 'provisional_completion';

// 年齢審査条件 18歳以上〜75歳以下（申込時）
export const OLDEST_AGE = 75;
export const YOUNGEST_AGE = 18;
export const MIDDLE_AGE = 40;

// ラジオボタンの表示内容とvalue
export const YES_OR_NO = [
  '有',
  '無',
];
export const YES_OR_NO_2 = [
  'はい',
  'いいえ',
];
export const YES_OR_NO_3 = [
  '1',
  '0',
];

// ラジオボタンの表示内容とvalue（世帯情報）
export const HOUSING_TYPE_FAMILY = '2';
export const HOUSING_TYPE = [
  '自己所有（マンション・一戸建て）',
  '家族所有',
  '賃貸マンション・借家・アパート',
  '社宅',
];
export const HOUSING_TYPE_2 = [
  '1',
  HOUSING_TYPE_FAMILY,
  '3',
  '4',
];

// ラジオボタンの表示内容とvalue（お勤め先情報）
export const OCCUPATION_SELF_EMPLOYED = '自営';
export const OCCUPATION_WORKING = 'お勤め';
export const OCCUPATION_OTHER = 'その他（専業主婦・学生・年金生活者）';

export const OCCUPATION_TYPE = [
  OCCUPATION_SELF_EMPLOYED,
  OCCUPATION_WORKING,
  OCCUPATION_OTHER,
];
export const OCCUPATION_TYPE_2 = [
  '1',
  '2',
  '3',
];

export const OTHER_INDUSTRY_TYPE = '専業主婦・学生・年金';

export const INDUSTRY_TYPE = [
  '公務員',
  '公的資格者',
  '一次産業',
  '鉱業',
  '土木・建築',
  '不動産',
  '金融',
  '運輸・倉庫',
  '製造',
  '卸',
  '小売',
  '情報・通信',
  '旅行・宿泊',
  'その他サービス',
  '医療機関',
  '組合・団体',
  '施設・機関',
];

export const INDUSTRY_TYPE_2: {
  [
    key: string
  ]: string[];
} = {
  公務員: ['教員', '警察署', '消防署', '自衛隊', '交通局', '一般行政事務職', '郵便局', 'その他公務'],
  公的資格者: ['医師', '弁護士', '公認会計士', '税理士', '大学教授', '一級建築士', '不動産鑑定士', '司法書士', 'その他公的'],
  一次産業: ['農業', '漁業', '林業', '畜産業'],
  鉱業: ['鉱業'],
  '土木・建築': ['大工・左官・とび職', '建築・工事', '測量・調査・設計', '塗装', '設備工事', '建設資材'],
  不動産: ['不動産'],
  金融: ['銀行・信金・信組', '保険（生命）', '保険（損害）', '保険代理業', '証券', 'クレジット・リース・レンタル', '質屋', 'その他金融'],
  '運輸・倉庫': ['陸運', '海運', '航空', '鉄道', 'バス', 'タクシー', '倉庫', '梱包・荷造'],
  製造: ['食品', '繊維・紙・パルプ', '化学', '金属', '電気機器', '輸送用機器', '情報・精密機器', '石油ガス等エネルギー', '印刷', 'その他製造'],
  卸: ['貿易', '一般卸・問屋', '仲買', '取引所'],
  小売: ['デパート', 'スーパー', 'コンビニエンスストアー', 'ディスカウントショップ', '一般商店', '訪問販売', '通信販売'],
  '情報・通信': ['新聞社', '放送', '通信', '出版', '広告', '興信・市場調査', '情報サービス'],
  '旅行・宿泊': ['旅行業', 'ホテル・旅館', 'レジャー施設'],
  その他サービス: ['飲食業', '遊技場', '風俗', 'スポーツ施設・健康', '教育', '介護・福祉', '宗教', '美容・理容', '清掃業', '警備', '整備・修理', '人材斡旋', 'その他サービス'],
  医療機関: ['病院・医院', '治療'],
  '組合・団体': ['組合・団体'],
  '施設・機関': ['施設・機関'],
  [OTHER_INDUSTRY_TYPE]: ['専業主婦', '学生', '年金生活者'],
};

export const POSITION = [
  '会社役員',
  '正社員（管理職）',
  '正社員',
  '派遣社員・契約社員',
  'アルバイト・パート',
];
