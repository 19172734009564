/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable default-case */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */
export enum MAKER_ID {
  /** 未選択 */
  UNSELECTED = -1,
  /** レクサス */
  LEXUS = 1,
  /** トヨタ */
  TOYOTA = 2,
  /** 日産 */
  NISSAN = 3,
  /** ホンダ */
  HONDA = 4,
  /** マツダ */
  MAZDA = 5,
  /** スバル */
  SUBARU = 6,
  /** スズキ */
  SUZUKI = 7,
  /** ダイハツ */
  DAIHATSU = 8,
  /** 三菱 */
  MITSUBISHI = 9,
}

export const MAKER_DATA = [
  {
    id: MAKER_ID.UNSELECTED,
    name: '',
    metaName: '',
    key: '',
    isUnselected: true,
    img: '',
    count: 0,
  },
  {
    id: MAKER_ID.LEXUS,
    name: 'レクサス',
    metaName: 'レクサス(LEXUS)',
    key: 'lexus',
    isUnselected: false,
    img: 'lexus.png',
    count: 0,
  },
  {
    id: MAKER_ID.TOYOTA,
    name: 'トヨタ',
    metaName: 'トヨタ(TOYOTA)',
    key: 'toyota',
    isUnselected: false,
    img: 'toyota.png',
    count: 0,
  },
  {
    id: MAKER_ID.NISSAN,
    name: '日産',
    metaName: '日産(ニッサン/NISSAN)',
    key: 'nissan',
    isUnselected: false,
    img: 'nissan.png',
    count: 0,
  },
  {
    id: MAKER_ID.HONDA,
    name: 'ホンダ',
    metaName: 'ホンダ(HONDA)',
    key: 'honda',
    isUnselected: false,
    img: 'honda.png',
    count: 0,
  },
  {
    id: MAKER_ID.MAZDA,
    name: 'マツダ',
    metaName: 'マツダ(MAZDA)',
    key: 'mazda',
    isUnselected: false,
    img: 'mazda.png',
    count: 0,
  },
  {
    id: MAKER_ID.SUBARU,
    name: 'スバル',
    metaName: 'スバル(SUBARU)',
    key: 'subaru',
    isUnselected: false,
    img: 'subaru.png',
    count: 0,
  },
  {
    id: MAKER_ID.SUZUKI,
    name: 'スズキ',
    metaName: 'スズキ(SUZUKI)',
    key: 'suzuki',
    isUnselected: false,
    img: 'suzuki.png',
    count: 0,
  },
  {
    id: MAKER_ID.DAIHATSU,
    name: 'ダイハツ',
    metaName: 'ダイハツ(DAIHATSU)',
    key: 'daihatsu',
    isUnselected: false,
    img: 'daihatsu.png',
    count: 0,
  },
  {
    id: MAKER_ID.MITSUBISHI,
    name: '三菱',
    metaName: '三菱(ミツビシ/MITSUBISHI)',
    key: 'mitsubishi',
    isUnselected: false,
    img: 'mitsubishi.png',
    count: 0,
  },
];

// 内部関数idでデータ探索
const makerDataFind = (id: MAKER_ID): number => {
  const target = MAKER_DATA.findIndex((data) => (data.id === id));
  if (target >= 0) {
    return target;
  }
  throw new Error('Invalid value');
};

// メーカー名
export const MAKER_NAME = (id: MAKER_ID): string => MAKER_DATA[makerDataFind(id)].name;

// メーカー名
export const MAKER_META_NAME = (id: MAKER_ID): string => MAKER_DATA[makerDataFind(id)].metaName;

// ID → KEY
export const MAKER_ID_TO_KEY = (id: MAKER_ID): string => MAKER_DATA[makerDataFind(id)].key;

// KEY → ID
export const MAKER_KEY_TO_ID = (key: string): MAKER_ID => {
  const target = MAKER_DATA.find((data) => (data.key === key));
  if (target) {
    return target.id;
  }
  throw new Error('Invalid value');
};
