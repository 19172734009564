/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useContext } from 'react';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import iconLinkBlank from '@/newcar/ui/detail/img/icon-link-blank.svg';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import * as styles from '@/newcar/ui/detail/organism/PlanDialog.module.styl';

interface ModalMaintenanceProps {
  next: (current: number, max: number) => void;
  currentStep: number;
  maxStep: number;
}

const ModalMaintenance: FC<ModalMaintenanceProps> = ({ next, currentStep, maxStep }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const getColorStyle = (id: NEWCAR_CONST.MAINTENANCE.PLAN) => {
    switch (id) {
      case NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM:
        return styles.maintePlatinum;
      case NEWCAR_CONST.MAINTENANCE.PLAN.GOLD:
        return styles.mainteGold;
      case NEWCAR_CONST.MAINTENANCE.PLAN.SILVER:
        return styles.mainteSilver;
      default:
        return styles.mainteWhite;
    }
  };

  return (
    <div className={`${styles.content} is-padding-5`}>
      <p className={`has-text-centered is-size-5 has-text-grey-dark is-margin-bottom-4 ${simulationManagerStore.maintenancePlanList.length > 0 ? '' : 'is-hidden'}`}>※以下の表示金額は月額税込の金額です</p>
      <div className="columns is-mobile">
        <div className="column is-one-fifth is-hidden-touch" />
        <div className={`column ${simulationManagerStore.maintenancePlanList.length > 0 ? '' : 'is-hidden'}`}>
          <ul className="is-margin-bottom-6">
            {(() => simulationManagerStore.maintenancePlanList.map((value, index) => (
              <li className="is-padding-1" key={index.toString()}>
                <div
                  className={`box is-marginless is-full-width is-relative ${styles.maintenanceBox} ${value.isChecked ? styles.maintenanceBoxSelected : ''}`}
                  data-gtm="gtm-newcar-simulator-modalMainte-maintePlan"
                  onClick={() => {
                    simulationManagerStore.changeMaintenance(value.id);
                    next(currentStep, maxStep);
                  }}
                >
                  <p className={`is-size-4 ${styles.osusumeLabel} ${index === 0 ? '' : 'is-hidden'}`}>
                    <i>オススメ</i>
                  </p>
                  <div className="columns is-mobile is-vcentered is-full-width is-padding-bottom-1">
                    <i className={`is-margin-left-5 ${styles.mainteplanIcon} ${getColorStyle(value.id)}`} />
                    <p className="column is-size-5 has-text-black-ter has-text-left is-padding-left-3 is-padding-right-5">{value.name}</p>
                    <p className="column is-size-5 is-narrow has-text-black-ter">
                      <strong>
                        <span className={`${styles.plus}`}>+</span>
                        <span className="is-margin-left-2 is-margin-right-2">{value.price}</span>
                      </strong>
                      <span className="is-size-7">円</span>
                    </p>
                  </div>
                  {value.overview && (
                    <div className={`column is-size-5 has-text-black-ter has-text-left is-padding-left-5 is-12-mobile is-padding-top-1 ${styles.overview}`}>
                      {value.overview}
                    </div>
                  )}
                </div>
              </li>
            )))()}
          </ul>
        </div>
        <div className="column is-one-fifth is-hidden-touch" />
      </div>
      {/* 説明 */}
      <div className="columns is-mobile">
        <div className="column is-one-fifth is-hidden-touch" />
        <div className={`column ${simulationManagerStore.maintenancePlanList.length > 0 ? '' : 'is-hidden'}`}>
          <dl className={`columns is-mobile has-text-centered is-size-5 is-size-7-mobile ${styles.maintenanceTableHead}`}>
            <dt className="column is-border-bottom-line">
              <div className="has-background-primary has-text-white">対象項目</div>
            </dt>
            <dd className="column is-narrow is-border-bottom-line">
              <div className="has-text-white">プラチナ</div>
            </dd>
            <dd className="column is-narrow is-border-bottom-line">
              <div className="has-text-white">ゴールド</div>
            </dd>
            <dd className="column is-narrow is-border-bottom-line">
              <div className="has-text-white">シルバー</div>
            </dd>
          </dl>
          <dl className={`columns is-mobile ${styles.maintenanceTableBody}`}>
            <dt className="column is-size-6 is-padding-1 is-border-bottom-line">
              <div className="has-text-black-ter">原状回復費用補償、車検点検料、エンジンオイル交換など</div>
            </dt>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-white has-text-centered">○</div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">◯</div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">◯</div>
            </dd>
          </dl>
          <dl className={`columns is-mobile ${styles.maintenanceTableBody}`}>
            <dt className="column is-size-6 is-padding-1 is-border-bottom-line">
              <div className="has-text-black-ter">
                タイヤメンテナンス、ブレーキオイル交換、ブレーキパッド交換など
              </div>
            </dt>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-white has-text-centered">○</div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">◯</div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">✕</div>
            </dd>
          </dl>
          <dl className={`columns is-mobile ${styles.maintenanceTableBody}`}>
            <dt className="column is-size-6 is-padding-1 is-border-bottom-line">
              <div className="has-text-black-ter">
                メーカー一般保証終了後の長期延長保証
              </div>
            </dt>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-white has-text-centered">
                ○
                <span className="is-size-7">※1</span>
              </div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">
                ◯
                <span className="is-size-7">※2</span>
              </div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">
                ◯
                <span className="is-size-7">※2</span>
              </div>
            </dd>
          </dl>
          <dl className={`columns is-mobile ${styles.maintenanceTableBody}`}>
            <dt className="column is-size-6 is-padding-1 is-border-bottom-line">
              <div className="has-text-black-ter">
                メーカー特別保証終了後の長期延長保証
              </div>
            </dt>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-white has-text-centered">
                ○
                <span className="is-size-7">※1</span>
              </div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">✕</div>
            </dd>
            <dd className="column is-narrow is-size-3 is-size-4-mobile is-border-bottom-line">
              <div className="has-text-black-ter has-text-centered">✕</div>
            </dd>
          </dl>
          <p className="is-size-6 has-text-black-ter">※プラチナプランはリース期間6年～11年でご加入いただけます。</p>
          <p className="is-size-6 has-text-black-ter">※1 リース期間満了または走行距離10万km</p>
          <p className="is-size-6 has-text-black-ter">※2 メーカー保証期間後から2年または走行距離10万km</p>
        </div>
        <div className="column is-one-fifth is-hidden-touch" />
      </div>
      <div className={`column is-padding-4 has-text-centered ${simulationManagerStore.maintenancePlanList.length === 0 ? '' : 'is-hidden'}`}>
        ご選択いただいている車種では
        <br />
        メンテナンスプランをご利用いただくことはできません。
      </div>
      <div className="has-text-centered is-margin-top-5 is-margin-bottom-5">
        <a href={NEWCAR_DEFINE.PATH.MAINTENANCE} target="_blank" className="is-size-5 has-text-link is-vcentered" data-gtm="gtm-newcar-simulator-modalMainte-toMaintePageLink" rel="noreferrer">
          メンテナンスプランの詳細を見る
          <img className={`is-margin-left-3 ${styles.verticalAlignTextTop}`} width={15} height={15} src={iconLinkBlank} alt="リンクアイコン" />
        </a>
      </div>
      <div className="column is-one-fifth is-hidden-touch" />
    </div>
  );
};

export default ModalMaintenance;
