/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useContext } from 'react';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import * as styles from '@/newcar/ui/detail/organism/PlanDialog.module.styl';
import NEWCAR_CONST from '@/newcar/util/const';

interface ModalBodyColorProps {
  next: (current: number, max: number) => void;
  currentStep: number;
  maxStep: number;
  selectedPlanID: NEWCAR_CONST.SIMULATION_PLAN.ID;
}

const ModalBodyColor: FC<ModalBodyColorProps> = ({
  next, currentStep, maxStep, selectedPlanID,
}) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const plansBodyColorList = (): Array<{
    id: string, bodyColorInfoId: string,
    bodyColorName?: string, colorCode1?: string, colorCode2?: string,
    price?: number, isChecked?: boolean
  }> => {
    if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      return simulationManagerStore.simplePlansBodyColorList;
    } if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      return simulationManagerStore.recommendPlansBodyColorList;
    } if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      return simulationManagerStore.richPlansBodyColorList;
    }
    // オリジナルプラン
    return simulationManagerStore.bodyColorList;
  };

  const changeBodyColor = (id:string) => {
    if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      // シンプルプラン
      simulationManagerStore.changeSimpleBodyColor(id);
    } else if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      // おすすめプラン
      simulationManagerStore.changeRecommendBodyColor(id);
    } else if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      // よくばりプラン
      simulationManagerStore.changeRichBodyColor(id);
    } else {
      // オリジナルプラン
      simulationManagerStore.changeBodyColor(id);
    }
  };

  return (
    <div className={`${styles.content} is-padding-5`}>
      <p className="has-text-centered is-size-5 has-text-grey-dark is-margin-bottom-4">※以下の表示金額は月額税込の金額です</p>
      <ul className="columns is-multiline is-margin-bottom-6">
        {(() => plansBodyColorList().map((value, index) => {
          const colorCircle = value.colorCode2 ? (
            <>
              <svg viewBox="0 0 32 32" width="32" height="32">
                <circle r="16" cx="16" cy="16" fill={value.colorCode1} stroke="#aaa" strokeWidth="0.5px" />
              </svg>
              <svg viewBox="0 0 32 10" width="32" height="10">
                <clipPath id={`clip_${index}`}>
                  <rect x="0" y="0" width="32" height="10" />
                </clipPath>
                <circle r="16" cx="16" cy="16" fill={value.colorCode2 || ''} stroke="#aaa" strokeWidth="0.5px" clipPath={`clip_${index}`} />
              </svg>
            </>
          ) : (
            <svg viewBox="0 0 32 32" width="32" height="32">
              <circle r="16" cx="16" cy="16" fill={value.colorCode1} stroke="#aaa" strokeWidth="0.5px" />
            </svg>
          );
          return (
            <li className="column is-full-touch is-half-desktop is-padding-1" key={index.toString()}>
              <div
                className={`is-full-width has-text-black-ter is-relative ${styles.bodycolorBox} ${value.isChecked ? styles.bodycolorBoxSelected : ''}`}
                data-gtm="gtm-newcar-simulator-modalBodyColor-bodycolor"
                onClick={() => {
                  changeBodyColor(value.id);
                  next(currentStep, maxStep);
                }}
              >
                <div className="columns is-mobile is-vcentered">
                  <div className={`column is-narrow ${styles.colorCircleWrap}`}>
                    {colorCircle}
                  </div>
                  <p className="column is-size-5 has-text-left is-padding-left-3 is-padding-right-3">{value.bodyColorName}</p>
                  <div className="column is-narrow is-valign-center">
                    <span className="is-size-4 has-text-weight-bold">{value.price !== 0 ? `+${value.price}` : value.price}</span>
                    <span className="is-size-6">円</span>
                  </div>
                </div>
              </div>
            </li>
          );
        }))()}
      </ul>
    </div>
  );
};

export default ModalBodyColor;
