/* eslint-disable @typescript-eslint/naming-convention */
import { OLDEST_AGE, YOUNGEST_AGE } from '@/newcar/util/const/contract';

/**
 * カルモで申込み可能な年数（西暦）をリストで返す
 *
 * @export
 * @returns カルモでの申込が可能な西暦のリスト
 */
export function getAvailableYearList(): number[] {
  const ONE_YEAR_FOR_OLDEST_PERSON_IS_BEFORE_BIRTHDAY = 1;
  const ageRange = OLDEST_AGE
    + ONE_YEAR_FOR_OLDEST_PERSON_IS_BEFORE_BIRTHDAY
    - YOUNGEST_AGE;
  const oldestYear = new Date().getFullYear()
    - OLDEST_AGE
    - ONE_YEAR_FOR_OLDEST_PERSON_IS_BEFORE_BIRTHDAY;
  return Array.from(new Array(ageRange + 1), (_x, i) => i + oldestYear);
}

/**
 * 指定位置までスムーススクロール
 * @param id
*/
export function scrollToId(id: string, height?: number): void {
  if (id) {
    // 画面の変更を考慮して気持ち遅らせる
    setTimeout(() => {
      const target: HTMLElement | null = document.getElementById(id);
      if (target) {
        window.scrollTo({
          top:
            window.scrollY + target.getBoundingClientRect().top - (height || 0),
          behavior: 'smooth',
        });
      }
    }, 100);
  }
}
