import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import * as styles from '@/newcar/ui/detail/organism/modal/ModalSP.module.styl';

interface ModalTermProps {
  isShow: boolean;
}

const ModalTerm: FC<ModalTermProps> = ({ isShow }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const termList = [
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_11,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_11],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term11SelectPrice)}円`,

    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_10,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_10],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term10SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_9,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_9],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term9SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_8,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_8],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term8SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_7,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_7],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term7SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_6,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_6],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term6SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_5,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_5],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term5SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_4,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_4],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term4SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_3,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_3],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term3SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_2,
      name: NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_2],
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term2SelectPrice)}円`,
    },
    {
      id: NEWCAR_CONST.TERM.ID.YEAR_1,
      name: `${NEWCAR_CONST.TERM.NAME[NEWCAR_CONST.TERM.ID.YEAR_1]}(最短)`,
      price: `${CARMO_CONST.COMMA_FORMAT(simulationManagerStore.term1SelectPrice)}円`,
    },
  ];

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isShow) {
      setStyle({});
    } else {
      // コンテンツの高さが一番高いところで揃ってしまうので非表示の時は高さを0にする
      setTimeout(() => setStyle({ height: '0px' }), 400);
    }
  }, [isShow]);

  const onClick = (id: NEWCAR_CONST.TERM.ID) => {
    const prevTerm = simulationManagerStore.nowSelectTerm;
    if (simulationManagerStore.nowSelectMaintenance
      === NEWCAR_CONST.MAINTENANCE.PLAN_NAME(NEWCAR_CONST.MAINTENANCE.PLAN.PLATINUM)) {
      if (id <= 5 && prevTerm >= 6) {
        // 賃貸期間を6年以上から5年以下に変更した時
        simulationManagerStore.setIsShowSimulateAlert(true);
      } else if (prevTerm <= 5 && id >= 6) {
        // 賃貸期間を5年以下から6年以上に変更した時
        simulationManagerStore.setIsShowSimulateAlert(true);
      }
    }
    simulationManagerStore.changeTerm(id);
  };

  return (
    <div className={`${styles.content} is-padding-5`} style={style} data-type="term-setting">
      <h2 className="has-text-centered is-margin-bottom-3 is-size-3 has-text-weight-light">ご利用期間をえらぶ</h2>
      <div className="is-margin-bottom-6">
        {termList.map((value, index) => (
          <>
            <div
              className={`${styles.radioRow} is-padding-1 ${(value.id === NEWCAR_CONST.TERM.ID.YEAR_11) ? `${styles.termInformationContainer}` : ''}`}
              key={index.toString()}
            >
              <input
                type="radio"
                name="term"
                id={`term-${value.id}`}
                checked={value.id === simulationManagerStore.nowSelectTerm}
                onChange={() => onClick(value.id)}
              />
              <label htmlFor={`term-${value.id}`} className="box is-padding-5">
                <span>
                  {value.name}
                  {value.id === NEWCAR_CONST.TERM.ID.YEAR_11 && (
                    <span className={styles.termTipAffordable}>最安</span>
                  )}
                </span>
                <span>{value.price}</span>
              </label>
            </div>
            {value.id === NEWCAR_CONST.TERM.ID.YEAR_7 && (
              <p className={styles.termNoticeUnder}>
                <span className={styles.isTextRed}>7年以上</span>
                から
                <span className="has-text-weight-bold">走行距離無制限・もらえる</span>
                オプション※が選べます
              </p>
            )}
          </>
        ))}
        <span className={styles.termNoticeListBottom}>
          ※「もらえるオプション」とはリース期間満了後にクルマがもらえるオプションです。
        </span>
      </div>
    </div>
  );
};

export default ModalTerm;
