import React, { FC } from 'react';

import Card from '@/newcar/ui/detail/molecule/Card';
import orixLogoWebp from '@/newcar/ui/detail/img/orix_logo.webp';
import orixLogoJpg from '@/newcar/ui/detail/img/orix_logo.jpg';
import * as styles from '@/newcar/ui/detail/organism/Partner.module.styl';

const Reason: FC = () => (
  <section className="container">
    <Card className={`${styles.padding}`}>
      <h2 className="title is-3 has-text-centered is-margin-bottom-3">提携先</h2>
      <div className="columns">
        <picture className="is-block has-text-centered column is-narrow">
          <source srcSet={orixLogoWebp} type="image/webp" />
          <source srcSet={orixLogoJpg} />
          <img src={orixLogoJpg} alt="オリックス自動車ロゴ" loading="lazy" decoding="async" width="331" height="82" />
        </picture>
        <p className={`column is-padding-4 is-margin-top-4 ${styles.spBorder}`}>
          おトクにマイカー 定額カルモくんは、オリックス自動車株式会社との提携サービスとなり、お客様にお届けする新車は、すべてオリックス自動車が扱う正規ディーラー車です。
        </p>
      </div>
    </Card>
  </section>
);

export default Reason;
