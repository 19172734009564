import React, { FC } from 'react';

import { Digest } from '@/newcar/util/const/digest';

const styles = require('./Color.module.styl');

type ColorProps = {
  // 2色の場合は間にスラッシュが入る．例: ["#f4f4f4", "#fcfbf4/#030101"]
  bodyColors: Digest['bodyColors'];
}

const Color: FC<ColorProps> = ({ bodyColors }) => {
  // カラーコードをRGBに変換して足し合わせる
  const calcRgb = (colorCodeOrigin: string): number => {
    let colorCode = colorCodeOrigin;
    if (colorCodeOrigin.split('')[0] === '#') {
      colorCode = colorCodeOrigin.substring(1);
    }
    return parseInt(colorCode.substring(0, 2), 16)
            + parseInt(colorCode.substring(2, 4), 16)
            + parseInt(colorCode.substring(4, 6), 16);
  };

  // ソート関数
  const compareColors = (
    a: {color1: string; color2: string | undefined}, b: {color1: string, color2: string| undefined},
  ): number => {
    if (a.color2 && !b.color2) {
      return 1;
    } if (!a.color2 && b.color2) {
      return -1;
    }
    return (calcRgb(a.color1) < calcRgb(b.color1) ? 1 : -1);
  };

  const sortColors = (
    colors: { color1: string, color2: string | undefined }[],
  ): { color1: string, color2: string | undefined }[] => {
    colors.sort((
      a: { color1: string; color2: string | undefined },
      b: { color1: string; color2: string | undefined },
    ) => compareColors(a, b));

    return colors;
  };

  const resultColors = sortColors(
    bodyColors.filter(Boolean).map((color) => {
      const [color1, color2] = color.split('/');
      return { color1, color2 };
    }),
  );

  const size = {
    width: '15', r: '6', cx: '7', cy: '7', halfHeight: '5',
  };

  const liArray = resultColors.map(({ color1, color2 }, index, array) => {
    if (index === 7) {
      if (array.length > 7) {
        return (
          <li key={index.toString()}>…</li>
        );
      }
    }

    if (index > 7) {
      return (
        <li key={index.toString()}> </li>
      );
    }

    if (color2) {
      return (
        <li className={`column is-narrow is-target-li ${styles.partsWrap}`} key={index.toString()}>
          <svg className={`is-target-svg ${styles.parts}`} viewBox={`0 0 ${size.width} ${size.width}`} width={size.width} height={size.width}>
            <circle r={size.r} cx={size.cx} cy={size.cy} fill={color1} stroke="#aaa" strokeWidth={0.5} />
          </svg>
          <svg className={`is-target-svg ${styles.parts}`} viewBox={`0 0 ${size.width} ${size.halfHeight}`} width={size.width} height={size.halfHeight}>
            <clipPath id={`clip_${index}`}>
              <rect x="0" y="0" width={size.width} height={size.halfHeight} />
            </clipPath>
            <circle r={size.r} cx={size.cx} cy={size.cy} fill={color2} stroke="#aaa" strokeWidth={0.5} clipPath={`clip_${index}`} />
          </svg>
        </li>
      );
    }

    return (
      <li className={`column is-narrow is-target-li ${styles.partsWrap}`} key={index.toString()}>
        <svg className={`is-target-svg ${styles.parts}`} viewBox={`0 0 ${size.width} ${size.width}`} width={size.width} height={size.width}>
          <circle r={size.r} cx={size.cx} cy={size.cy} fill={color1} stroke="#aaa" strokeWidth={0.5} />
        </svg>
      </li>
    );
  });

  return (
    <ul className="columns is-mobile is-vcentered is-target-ul">
      {liArray}
    </ul>
  );
};

export default Color;
