import React, { FC } from 'react';
import checkicon from '@/newcar/ui/detail/img/checkicon.svg';
import * as styles from '@/newcar/ui/detail/organism/RecommendedPointsSummary.module.styl';
import RecommendedPointsSummaryType from '@/newcar/core/model/recommendedPointsSummary';

import good from '@/newcar/ui/detail/img/rating/good.svg';
import great from '@/newcar/ui/detail/img/rating/great.svg';
import excellent from '@/newcar/ui/detail/img/rating/excellent.svg';
import storeManager from '@/newcar/ui/detail/img/rating/store-manager.png';
import commentShape from '@/newcar/ui/detail/img/rating/comment-shape.svg';

type RatingImagesType = {
  good: string;
  great: string;
  excellent: string;
}
const ratingImages: RatingImagesType = {
  good,
  great,
  excellent,
};

interface RecommendedPointsSummaryProps {
  className?: string
  carName: string;
  points: string[];
  recommendedPointsSummary: RecommendedPointsSummaryType;
}
const RecommendedPointsSummary: FC<RecommendedPointsSummaryProps> = ({
  className, carName, recommendedPointsSummary, points,
}) => {
  const {
    storeManagerRemark, rating,
  } = recommendedPointsSummary;
  const filteredPoints = points.filter((point) => !!point);

  return (
    <>
      <div id="recommended-points-summary" className={className || ''}>
        <h2 className={`${styles.recommendedPointsHeading}`}>{`${carName}のココが良い！`}</h2>
        <div className="">
          <div className={`${styles.storeManagerArea}`}>
            {/* 店長の顔 */}
            <div className=""><img className={`${styles.storeManagerImage}`} src={storeManager} alt="店長のコメント" /></div>
            {/* 店長の一言 */}
            <div className={`${styles.storeManagerComment}`}>
              <p className="has-text-weight-semibold">{storeManagerRemark}</p>
              <span className="is-size-6">(カルモくん店長 若林)</span>
              <img className={`${styles.commentShape}`} src={commentShape} alt="コメント吹き出し" />
            </div>
          </div>
          <div className={`${styles.recommendedArea}`}>
            {/* おすすめポイント */}
            {
                filteredPoints && filteredPoints.length > 0 && (
                  <div className={`${styles.recommendedPointsArea}`}>
                    <div className="has-text-centered">
                      <div className={`${styles.recommendedPointsAreaHeading}`}>このような方におすすめ！</div>
                    </div>
                    <ul>
                      {filteredPoints.map((point) => (
                        <li key={point} className={`${styles.recommendedPointsItem}`}>
                          <img src={checkicon} alt="矢印" />
                          <p>{point}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              }
            {/* テーブル画像 */}
            <table className={`${styles.carRatingTable}`}>
              <tbody>
                <tr>
                  <td className={`${styles.tableUpperLeftCorner}`}>
                    <span>燃費</span>
                    <span><img src={ratingImages[rating.fuelEfficiency]} alt="fuelEfficiency" /></span>
                  </td>
                  <td className={`${styles.tableUpperRightCorner}`}>
                    <span>通勤</span>
                    <span><img src={ratingImages[rating.commute]} alt="commute" /></span>
                  </td>
                </tr>
                <tr className={`${styles.borderBottomGrey} ${styles.backgroundGrey}`}>
                  <td className={`${styles.borderRightGrey} ${styles.borderLeftGrey}`}>
                    <span>ファミリー</span>
                    <span><img src={ratingImages[rating.family]} alt="family" /></span>
                  </td>
                  <td className={`${styles.borderRightGrey}`}>
                    <span>運転しやすさ</span>
                    <span><img src={ratingImages[rating.easeOfDriving]} alt="easeOfDriving" /></span>
                  </td>
                </tr>
                <tr className={`${styles.borderBottomGrey}`}>
                  <td className={`${styles.borderRightGrey} ${styles.borderLeftGrey}`}>
                    <span>乗り心地</span>
                    <span><img src={ratingImages[rating.rideComfort]} alt="rideComfort" /></span>
                  </td>
                  <td className={`${styles.borderRightGrey}`}>
                    <span>収納スペース</span>
                    <span><img src={ratingImages[rating.storageSpace]} alt="storageSpace" /></span>
                  </td>
                </tr>
                <tr className={`${styles.borderBottomGrey} ${styles.backgroundGrey}`}>
                  <td className={`${styles.borderRightGrey} ${styles.borderLeftGrey}`}>
                    <span>室内空間</span>
                    <span><img src={ratingImages[rating.interiorSpace]} alt="interiorSpace" /></span>
                  </td>
                  <td className={`${styles.borderRightGrey}`}>
                    <span>アウトドア</span>
                    <span><img src={ratingImages[rating.outdoor]} alt="outdoor" /></span>
                  </td>
                </tr>
                <tr>
                  <td className={`${styles.borderRightGrey} ${styles.tableLeftBottomCorner}`}>
                    <span>維持費</span>
                    <span><img src={ratingImages[rating.maintenanceCost]} alt="maintenanceCost" /></span>
                  </td>
                  <td className={`${styles.tableRightBottomCorner}`}>
                    <span>女性向き</span>
                    <span><img src={ratingImages[rating.femaleFriendly]} alt="femaleFriendly" /></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendedPointsSummary;
