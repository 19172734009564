import React, {
  FC, useState, useContext, useEffect,
} from 'react';
import { Link } from 'gatsby';
import CarDigest from '@/common/core/model/carDigest';
import PlanSP from '@/newcar/ui/detail/organism/PlanSP';
import PlanPC from '@/newcar/ui/detail/organism/PlanPC';
import arrowBlack from '@/newcar/ui/detail/img/arrow-black.svg';
import PlanDialog from '@/newcar/ui/detail/organism/PlanDialog';
import { PlanTitle } from '@/newcar/core/model/plan';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import * as styles from '@/newcar/ui/detail/organism/Simulation.module.styl';
import NEWCAR_DEFINE from '@/newcar/util/define';
import CARMO_UTIL from '@/common/util/util';
import { ModeType } from '@/newcar/util/const/dialogMode';
import NEWCAR_CONST from '@/newcar/util/const';

type PickDigest = Pick<CarDigest, 'thumbnailUrl' | 'hasWantCar'>;

const Simulation: FC<PickDigest> = ({ thumbnailUrl, hasWantCar }) => {
  const [isShowDialog, setShowDialog] = useState(false);
  const [mode, setMode] = useState<ModeType>('all');
  const [selectedPlanID, setSelectedPlanID] = useState<NEWCAR_CONST.SIMULATION_PLAN.ID>(
    NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE,
  );
  const [initialStep, setInitialStep] = useState(1);

  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  // ダイアログを表示
  const onClickCustomizePlan = (
    dialogMode: ModeType,
    planID: NEWCAR_CONST.SIMULATION_PLAN.ID,
    step?: number,
  ): void => {
    setSelectedPlanID(planID);
    setShowDialog(true);
    setMode(dialogMode);
    if (step) setInitialStep(step);
  };

  // ダイアログを閉じるアニメーションのためのタイムアウト
  const timeoutToClose = ():void => {
    // シミレーションのエリアまでスクロール
    CARMO_UTIL.scrollToId('simulation', (mode === 'sp') ? 0 : 50);

    setTimeout(() => setShowDialog(false), 400);
  };

  // パスから車種IDを取得
  const getCarId = () => {
    const url = window.location.pathname.split('/');
    // URLの末尾が/の時とそうでない時で配列の最後の要素が変わるため
    const carId = url[url.length - 1] ? url[url.length - 1] : url[url.length - 2];
    return carId;
  };

  // ダイアログの表示切り替え時の処理
  useEffect(() => {
    const carId = getCarId();
    if (isShowDialog) {
      if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL) {
        simulationManagerStore.startSimulation(carId);
      }
    }
  }, [isShowDialog]);

  return (
    <section className="container" id="simulation">
      <div className={`is-hidden-touch is-margin-bottom-7 ${styles.isMarginTop3rem}`}>
        <div className="columns">
          <PlanPC
            title={PlanTitle.SIMPLE}
            onClickCustomizePlan={onClickCustomizePlan}
          />
          <PlanPC
            title={PlanTitle.OSUSUME}
            onClickCustomizePlan={onClickCustomizePlan}
            classname="is-margin-left-3"
          />
          <PlanPC
            title={PlanTitle.YOKUBARI}
            onClickCustomizePlan={onClickCustomizePlan}
            classname="is-margin-left-3"
          />
          <PlanPC
            title={PlanTitle.ORIGINAL}
            onClickCustomizePlan={onClickCustomizePlan}
            classname="is-margin-left-3"
            isOriginal
          />
        </div>
        <div className={`columns ${styles.detailBtnWrap}`}>
          <Link to={NEWCAR_DEFINE.PATH.MORAERU} className="column columns is-vcentered is-narrow is-size-6 has-text-grey is-margin-right-3">
            もらえるオプションを詳しく見る
            <img src={arrowBlack} alt="黒矢印" className={`is-margin-left-1 ${styles.arrowBlack}`} />
          </Link>
          <Link to={NEWCAR_DEFINE.PATH.MAINTENANCE} className="column columns is-narrow is-size-6 has-text-grey">
            メンテナンスプランの違いを詳しく見る
            <img src={arrowBlack} alt="黒矢印" className={`is-margin-left-1 ${styles.arrowBlack}`} />
          </Link>
        </div>
      </div>

      <div className="is-hidden-desktop">
        <PlanSP thumbnailUrl={thumbnailUrl} onClickCustomizePlan={onClickCustomizePlan} />
      </div>

      {isShowDialog && (
        <PlanDialog
          isShowDialog={isShowDialog}
          timeoutToClose={timeoutToClose}
          mode={mode}
          selectedPlanID={selectedPlanID}
          initialStep={initialStep}
          thumbnailUrl={thumbnailUrl}
          hasWantCar={hasWantCar}
        />
      )}
    </section>
  );
};

export default Simulation;
