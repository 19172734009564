import { useStaticQuery, graphql } from 'gatsby';
import { searchCarStore } from '@/newcar/core/store/searchCarStore';

export const setAllList = (): void => {
  const data = useStaticQuery<GatsbyTypes.allNewCarDetailAndDigestQuery>(graphql`
  query allNewCarDetailAndDigest {
    allCarDetail {
      nodes {
        bodyColorInfo {
          bodyColorName
          colorCode1
          colorCode2
          id
        }
        id
        optionFees {
          id
          leasingFee108
          leasingFee12
          leasingFee120
          leasingFee132
          leasingFee24
          leasingFee36
          leasingFee48
          leasingFee60
          leasingFee72
          leasingFee84
          leasingFee96
          supportFee108
          supportFee12
          supportFee120
          supportFee132
          supportFee24
          supportFee36
          supportFee48
          supportFee60
          supportFee72
          supportFee84
          supportFee96
        }
        optionInfo {
          description
          id
          name
          optionType
        }
        vehicleTypes {
          caption1
          caption2
          caption3
          captionImageUrl1
          captionImageUrl2
          captionImageUrl3
          id
          masterNumber
          photos {
            category
            imageUrl
            order
          }
          point1
          point2
          point3
          review
          summary
          vehicleGrades {
            automaticBrake
            bodyColors {
              bodyColorInfoId
              id
              optionFeeId
            }
            capacity
            cruiseControl
            displacement
            engine
            id
            keylessEntry
            kudou
            laneDepartureAlert
            leasingFee108
            leasingFee12
            leasingFee120
            leasingFee132
            leasingFee24
            leasingFee36
            leasingFee48
            leasingFee60
            leasingFee72
            leasingFee84
            leasingFee96
            mission
            name
            numDoors
            onlineDiscountFee108
            onlineDiscountFee12
            onlineDiscountFee120
            onlineDiscountFee132
            onlineDiscountFee24
            onlineDiscountFee36
            onlineDiscountFee48
            onlineDiscountFee60
            onlineDiscountFee72
            onlineDiscountFee84
            onlineDiscountFee96
            options {
              id
              isStandard
              optionFeeId
              optionInfoId
            }
            orderName
            pedalSupport
            powerSlideDoor
            smartKey
            supportFee108
            supportFee12
            supportFee120
            supportFee132
            supportFee24
            supportFee36
            supportFee48
            supportFee60
            supportFee72
            supportFee84
            supportFee96
            freeTransferDiffLeasingFeeTaxed12
            freeTransferDiffLeasingFeeTaxed24
            freeTransferDiffLeasingFeeTaxed36
            freeTransferDiffLeasingFeeTaxed48
            freeTransferDiffLeasingFeeTaxed60
            freeTransferDiffLeasingFeeTaxed72
            freeTransferDiffLeasingFeeTaxed84
            freeTransferDiffLeasingFeeTaxed96
            freeTransferDiffLeasingFeeTaxed108
            freeTransferDiffLeasingFeeTaxed120
            freeTransferDiffLeasingFeeTaxed132
          }
        }
      }
    }
    allCarDigest {
      nodes {
        masterNumber
        id__normalized
        name
        makerId
        publishStatus
        bodyType
        isMiniCar
        isHatchBack
        maintenanceType
        popularityScore
        wltc
        jc08
        supocar
        makerSiteUrl
        titleName
        descriptionName
        wordpressName
        minPrice
        taxedMinPrice11
        taxedMinPrice10
        taxedMinPrice9
        taxedMinPrice8
        taxedMinPrice7
        taxedMinPrice6
        taxedMinPrice5
        taxedMinPrice4
        taxedMinPrice3
        taxedMinPrice2
        taxedMinPrice1
        minVehiclePrice
        has4SeatOrLess
        has5Seat
        has6Seat
        has7Seat
        has8SeatOrMore
        hasMT
        has4WD
        hasHybrid
        hasPowerSlideDoor
        hasKeylessEntry
        hasSmartKey
        hasCruiseControl
        hasAutomaticBrake
        hasPedalSupport
        hasLaneDepartureAlert
        bodyColors
        thumbnailUrl
        isCriteoTarget
        hasWantCar
      }
    }
  }
  `);

  searchCarStore.setAllList([data.allCarDigest.nodes, data.allCarDetail.nodes[0]]);
};
