/* eslint-disable react/no-danger */
import React, { FC, useState } from 'react';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import * as styles from '@/newcar/ui/detail/organism/Introduction.module.styl';

type IntroductionProps = Pick<VehicleTypes, 'review' | 'summary' | 'captionImageUrl1' | 'captionImageUrl2' | 'captionImageUrl3' | 'caption1' | 'caption2' | 'caption3' | 'point1' | 'point2' | 'point3'>

const Introduction: FC<IntroductionProps> = ({
  review, captionImageUrl1, captionImageUrl2, captionImageUrl3, caption1, caption2, caption3,
  summary, point1, point2, point3,
}) => {
  const [introClose, setIntroClose] = useState(true);

  const src1 = captionImageUrl1 || '';
  const src2 = captionImageUrl2 || '';
  const src3 = captionImageUrl3 || '';

  const switchIntroClose = (): void => {
    setIntroClose(!introClose);
  };

  // 車種紹介(review)の中から、props の point1~3 とマッチする文章を削除
  let processedReview = review || '';
  if (!!point1 && !!point2 && !!point3) {
    const lines = processedReview.split('\n');
    lines.forEach((line, index) => {
      if (line.includes('はこのような方におすすめ')) {
        lines.splice(index, 2, 'delete-me', 'delete-me');
      }
      if (line.includes(point1)) {
        lines.splice(index, 1, 'delete-me');
      }
      if (line.includes(point2)) {
        lines.splice(index, 1, 'delete-me');
      }
      if (line.includes(point3)) {
        lines.splice(index, 3, 'delete-me', 'delete-me', 'delete-me');
      }
    });
    processedReview = lines.filter((line) => line !== 'delete-me').join('\n');
  }
  // *** ここまで ***

  return (
    <div id="car-model-introduction">
      <h2 className="title is-3 has-text-centered">車種紹介</h2>
      <div className={`is-size-5 ${introClose ? 'is-hidden' : ''}`}>
        <div className={`is-margin-7 is-size-4 has-text-weight-bold ${styles.htmlString}`} dangerouslySetInnerHTML={{ __html: summary as string }} />

        <div id="car-model-introduction-opened-img-area" className="columns has-text-centered is-margin-7 is-padding-bottom-7">
          <div className="column is-margin-5">
            <img className={`${styles.carModelImage}`} src={src1} alt="caption1" loading="lazy" decoding="async" width="375" height="195" />
            <p className="is-padding-top-2">{caption1}</p>
          </div>
          <div className="column is-margin-5">
            <img className={`${styles.carModelImage}`} src={src2} alt="caption2" loading="lazy" decoding="async" width="375" height="195" />
            <p className="is-padding-top-2">{caption2}</p>
          </div>
          <div className="column is-margin-5">
            <img className={`${styles.carModelImage}`} src={src3} alt="caption3" loading="lazy" decoding="async" width="375" height="195" />
            <p className="is-padding-top-2">{caption3}</p>
          </div>
        </div>
        <div className={`is-margin-7 ${styles.htmlString}`} dangerouslySetInnerHTML={{ __html: processedReview as string }} />
      </div>

      <div className={`${styles.closedBody} ${introClose ? '' : 'is-hidden'}`}>
        <div className={`is-padding-7 is-size-4 has-text-weight-bold ${styles.htmlString}`} dangerouslySetInnerHTML={{ __html: summary as string }} />
        <div id="car-model-introduction-closed-img-area" className="columns has-text-centered is-margin-7 is-padding-bottom-7">
          <div className="column is-margin-5">
            <img src={src1} alt="caption1" loading="lazy" decoding="async" width="375" height="195" />
            <p className="is-padding-top-2">{caption1}</p>
          </div>
          <div className="column is-margin-5">
            <img src={src2} alt="caption2" loading="lazy" decoding="async" width="375" height="195" />
            <p className="is-padding-top-2">{caption2}</p>
          </div>
          <div className="column is-margin-5">
            <img src={src3} alt="caption3" loading="lazy" decoding="async" width="375" height="195" />
            <p className="is-padding-top-2">{caption3}</p>
          </div>
        </div>
      </div>
      <div className="columns is-mobile is-centered is-padding-top-7">
        <button
          type="button"
          className={`button is-large is-outlined has-text-dark ${styles.moreButton}`}
          onClick={(e): void => {
            e.currentTarget.blur();
            switchIntroClose();
          }}
        >
          {introClose ? 'もっと見る' : '閉じる'}
        </button>
      </div>
    </div>

  );
};

export default Introduction;
