// extracted by mini-css-extract-plugin
export const recommendedPointsHeading = "RecommendedPointsSummary-module--recommended-points-heading--2-tzn";
export const recommendedPointsArea = "RecommendedPointsSummary-module--recommended-points-area--3X_aJ";
export const recommendedPointsAreaHeading = "RecommendedPointsSummary-module--recommended-points-area-heading--2sBG5";
export const recommendedPointsItem = "RecommendedPointsSummary-module--recommended-points-item--3eyQZ";
export const storeManagerArea = "RecommendedPointsSummary-module--store-manager-area--1vOka";
export const storeManagerImage = "RecommendedPointsSummary-module--store-manager-image--2tsUS";
export const storeManagerComment = "RecommendedPointsSummary-module--store-manager-comment--1ozDT";
export const commentShape = "RecommendedPointsSummary-module--comment-shape--OgmZX";
export const carRatingTable = "RecommendedPointsSummary-module--car-rating-table--gUsHz";
export const borderRightGrey = "RecommendedPointsSummary-module--border-right-grey--1MinL";
export const borderLeftGrey = "RecommendedPointsSummary-module--border-left-grey--1MJO5";
export const borderBottomGrey = "RecommendedPointsSummary-module--border-bottom-grey--184NN";
export const backgroundGrey = "RecommendedPointsSummary-module--background-grey--3qLkq";
export const tableUpperLeftCorner = "RecommendedPointsSummary-module--table-upper-left-corner--3-9Pv";
export const tableUpperRightCorner = "RecommendedPointsSummary-module--table-upper-right-corner--3OpSn";
export const tableLeftBottomCorner = "RecommendedPointsSummary-module--table-left-bottom-corner--3KY4-";
export const tableRightBottomCorner = "RecommendedPointsSummary-module--table-right-bottom-corner--2MUmK";
export const recommendedArea = "RecommendedPointsSummary-module--recommended-area--2wg57";