import React, { FC } from 'react';
import * as styles from '@/newcar/ui/detail/molecule/ColorSquare.module.styl';

type ColorSquareProps = {
  width: number,
  height: number,
  colorCode1?: string,
  colorCode2?: string,
}

const ColorSquare:FC<ColorSquareProps> = ({
  width, height, colorCode1, colorCode2,
}) => (
  <span style={{ width, height }} className="is-inline-block is-relative">
    {/* 1色目 */}
    <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height} className={styles.colorChip}>
      <rect fill={colorCode1} x="0" y="0" width={width} height={height} stroke="#aaaaaa" strokeWidth="0.5px" />
    </svg>
    {/* 2色目 */}
    {colorCode2 && (
      <svg viewBox={`0 0 ${width} ${height / 3}`} width={width} height={height / 3} className={styles.colorChip}>
        <clipPath id="simulator_bodycolor_clip">
          <rect x="0" y="0" width={width} height={height / 3} />
        </clipPath>
        <rect fill={colorCode2} clipPath="url(simulator_bodycolor_clip)" x="0" y="0" width={width} height={height / 3} stroke="#aaaaaa" strokeWidth="0.5px" />
      </svg>
    )}
  </span>
);

export default ColorSquare;
