import React, { FC } from 'react';
import { Link } from 'gatsby';

import NEWCAR_DEFINE from '@/newcar/util/define';

import Color from '@/newcar/ui/lineup/molecule/Color';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import loading from '@/newcar/ui/lineup/img/loading.svg';
import listArrow from '@/newcar/ui/lineup/img/icon-chevron-right-grey.svg';

import * as styles from './CarModelPanelRecommended.module.styl';

type RecommendedInformation = {
  name: string,
  makerName: string,
  link: string,
  thumbnailUrl: string,
  taxedMinPrice11: number,
  bodyColors: string[],
}

const CarModelPanelRecommended: FC<RecommendedInformation> = (
  {
    makerName,
    name,
    link,
    thumbnailUrl,
    taxedMinPrice11,
    bodyColors,
  },
) => {
  const gatsbyImageData : IGatsbyImageData = {
    layout: 'fullWidth',
    width: 240,
    height: 130,
    images: { fallback: { src: thumbnailUrl } },
    placeholder: { fallback: loading },
  };
  return (
    <div className={`card ${styles.carModelPanel}`}>
      <Link to={NEWCAR_DEFINE.PATH.DETAIL(link)}>
        <div className={`is-mobile ${styles.cardBox}`}>
          <div className={`${styles.cardBoxFigureContainer} column card-image is-padding-1 is-hidden-desktop`}>
            <figure className={`column ${styles.image}`}>
              <GatsbyImage image={gatsbyImageData} alt={name} />
            </figure>
          </div>
          <div className="column card-content is-padding-1">
            <p className={`${styles.hasTextGrey} has-text-left is-size-7 has-text-weight-semibold`}>
              {makerName}
            </p>
            <h3 className={`${styles.isMarginAdjust} has-text-left is-margin-top-1 is-padding-right-3`}>
              <strong className="is-size-4">{name}</strong>
            </h3>
            <p className="is-margin-top-3 has-text-left">
              <span className="is-size-3 has-text-weight-semibold is-margin-right-1">
                {taxedMinPrice11.toLocaleString()}
              </span>
              <span className="is-size-6 has-text-grey-light">円〜/月</span>
            </p>
            {/* 画像 */}
            <div className="column card-image is-padding-1 is-hidden-touch">
              <figure className={`column ${styles.image}`}>
                <GatsbyImage image={gatsbyImageData} alt={name} />
              </figure>
            </div>
            {/* カラー */}
            <dl className="columns is-mobile has-text-grey-light is-size-7 is-padding-2 is-hidden-mobile">
              <dt className="column is-narrow is-inline-block is-margin-right-1 is-target-dt">カラー</dt>
              <dd className="column is-narrow is-inline-block is-target-dd">
                <Color bodyColors={bodyColors} />
              </dd>
            </dl>
          </div>
          {/* SP版のarrow */}
          <span className={styles.listArrow}><img src={listArrow} alt={name} /></span>
        </div>
      </Link>
    </div>
  );
};
export default CarModelPanelRecommended;
