import React from 'react';

interface ShowProps {
  when: boolean;
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

export default function Show(props: ShowProps): JSX.Element {
  const {
    when,
    fallback = <></>,
    children,
  } = props;

  if (when) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
}
