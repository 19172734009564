import React, { FC, useContext, useState } from 'react';
import { useLocation } from '@reach/router';
import arrowWhite from '@/newcar/ui/detail/img/arrow-white.svg';
import { PlanTitleToId, PlanTitle } from '@/newcar/core/model/plan';
import { ModeType } from '@/newcar/util/const/dialogMode';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import SimulationResultManagerStoreContext from '@/newcar/ui/common/context/SimulationResultManagerStoreContext';
import { Observer } from 'mobx-react-lite';

import * as styles from '@/newcar/ui/detail/organism/PlanPC.module.styl';
import NEWCAR_CONST from '@/newcar/util/const';
import CARMO_CONST from '@/common/util/const';
import { navigate } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';

type PlanPCProps = {
  title: string;
  onClickCustomizePlan: (
    dialogode: ModeType,
    planID: NEWCAR_CONST.SIMULATION_PLAN.ID
    )=>void;
  classname?: string;
  isOriginal?: boolean;
}

const PlanPC:FC<PlanPCProps> = ({
  title, onClickCustomizePlan, classname, isOriginal,
}) => {
  const location = useLocation();

  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const simulationResultManagerStore = useContext(SimulationResultManagerStoreContext);

  const [isSaved, setIsSaved] = useState(false);

  const hasGrade = () => {
    if (!simulationManagerStore.isOldSimulation) {
      const list = simulationManagerStore.nowSelectGrade;
      return (list !== undefined);
    }
    // 保存済みシミュレーションのため常にtrue
    return true;
  };

  interface PlanProps {
    gradeName?: string,
    option?: string,
    color?:string,
    maintenance?: string,
    price?:number,
    term?:NEWCAR_CONST.TERM.ID
  }

  // シンプルプラン系情報取得
  const simplePlan = (): PlanProps => simulationManagerStore.nowSimpleSelection;
  const simplePlanPrice = (): string => {
    const result = simulationManagerStore.nowSimpleSelection;
    return CARMO_CONST.COMMA_FORMAT(result.price);
  };

  // おすすめプラン系情報取得
  const recommendPlan = (): PlanProps => simulationManagerStore.nowRecommendSelection;
  const recommendPlanPrice = (): string => {
    const result = simulationManagerStore.nowRecommendSelection;
    return CARMO_CONST.COMMA_FORMAT(result.price);
  };

  // よくばりプラン系情報取得
  const richPlan = (): PlanProps => simulationManagerStore.nowRichSelection;
  const richPlanPrice = (): string => {
    const result = simulationManagerStore.nowRichSelection;
    return CARMO_CONST.COMMA_FORMAT(result.price);
  };

  // オリジナルプラン系情報取得
  const originalPlan = (): PlanProps => {
    if (!simulationManagerStore.isOldSimulation) {
      return simulationManagerStore.nowOriginalSelection;
    }
    return simulationResultManagerStore.oldOriginalSelection;
  };
  const originalPrice = (): string => {
    if (!simulationManagerStore.isOldSimulation) {
      const result = simulationManagerStore.nowOriginalSelection;
      return CARMO_CONST.COMMA_FORMAT(result.price);
    }
    const result = simulationResultManagerStore.oldOriginalSelection;
    return CARMO_CONST.COMMA_FORMAT(result.price);
  };

  const goToEntryForm = (plan: string) => {
    // シミュレーションの履歴を保存
    if (simulationManagerStore.nowCar) {
      simulationManagerStore.changeUserSelect(simulationManagerStore.nowCar);
    }

    if (plan === NEWCAR_CONST.SIMULATION_PLAN.NAME[NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL]) {
      if (!simulationManagerStore.isOldSimulation) {
        if (!hasGrade()) {
          simulationManagerStore.nowCarToUndefined();
        }
        simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
        navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
      } else if (simulationResultManagerStore.nowSimulation) {
        simulationResultManagerStore.saveEntryBreakdownFromSimulationResult(
          simulationResultManagerStore.nowSimulation,
        );
        navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
      }
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.NAME[NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE]) {
      // シンプルプラン
      simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE);
      navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.NAME[
      NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND]) {
      // おすすめプラン
      simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND);
      navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
    } else if (plan === NEWCAR_CONST.SIMULATION_PLAN.NAME[NEWCAR_CONST.SIMULATION_PLAN.ID.RICH]) {
      // よくばりプラン
      simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.RICH);
      navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
    }
  };

  // シミュレーション結果を保存
  const onClickSave = () => {
    simulationResultManagerStore.addSimulation({
      customerSelection: simulationManagerStore.nowCustomerSelection,
      carDigest: simulationManagerStore.nowCarDigest,
      grade: simulationManagerStore.nowGrade,
      bodyColor: simulationManagerStore.nowSimulationBodyColor,
      totalPrice: simulationManagerStore.nowSelectPrice,
      optionstring: simulationManagerStore.nowSelectOption,
      option: simulationManagerStore.nowSimulationOpstions,
    });
    // パラメータを変更
    if (window && !/s1=/.test(location.search)) {
      const paramstring = simulationManagerStore.urlParams;
      if (paramstring) {
        if (location.pathname.slice(-1) === '/') {
          window.history.replaceState(null, '', `${location.pathname}?${paramstring}&s1=1`);
        } else {
          window.history.replaceState(null, '', `${location.pathname}/?${paramstring}&s1=1`);
        }
      }
    }
    setIsSaved(true);
  };

  return (
    <Observer>
      {() => {
        let planName = '';
        let plan: PlanProps = {};
        let price = '';
        let gtmCta = '';
        let gtmCustom = '';
        switch (title) {
          case PlanTitle.SIMPLE:
            planName = NEWCAR_CONST.SIMULATION_PLAN.NAME[NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE];
            price = simplePlanPrice();
            plan = simplePlan();
            gtmCta = 'gtm-newcar-detail-simulatorPC-simplePlanCTABtn';
            gtmCustom = 'gtm-newcar-detail-simulatorPC-simplePlanCcustomBtn';
            break;
          case PlanTitle.OSUSUME:
            planName = NEWCAR_CONST.SIMULATION_PLAN.NAME[NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND];
            price = recommendPlanPrice();
            plan = recommendPlan();
            gtmCta = 'gtm-newcar-detail-simulatorPC-recommendPlanCTABtn';
            gtmCustom = 'gtm-newcar-detail-simulatorPC-recommendPlanCcustomBtn';
            break;
          case PlanTitle.YOKUBARI:
            planName = NEWCAR_CONST.SIMULATION_PLAN.NAME[NEWCAR_CONST.SIMULATION_PLAN.ID.RICH];
            price = richPlanPrice();
            plan = richPlan();
            gtmCta = 'gtm-newcar-detail-simulatorPC-richPlanCTABtn';
            gtmCustom = 'gtm-newcar-detail-simulatorPC-richPlanCcustomBtn';
            break;
          case PlanTitle.ORIGINAL:
            planName = NEWCAR_CONST.SIMULATION_PLAN.NAME[NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL];
            price = originalPrice();
            plan = originalPlan();
            gtmCta = 'gtm-newcar-detail-simulatorPC-originalPlanCTABtn';
            gtmCustom = 'gtm-newcar-detail-simulatorPC-originalPlanCcustomBtn';
            break;
          default:
            break;
        }
        const data = {
          planName,
          price,
          grade: plan.gradeName,
          color: plan.color,
          option: plan.option,
          maintenance: plan.maintenance,
          term: plan.term,
        };

        return (
          <div className={`column ${styles.isFlex} ${classname}`}>
            <div className={`card is-margin-bottom-5 has-background-white is-padding-4 is-padding-top-0 ${styles.isPaddingBottomLarge}`}>
              <div className={`has-background-primary ${styles.band} ${styles.bandSizeSmall}`} />
              <div className="is-padding-top-4">
                <div className={`columns ${styles.topBubble}`}>
                  {title === PlanTitle.OSUSUME && (
                  <>
                    <p className="is-size-7 has-background-white has-text-primary has-text-weight-semibold">カーナビ付</p>
                    {!simulationManagerStore.nowRecommendSelection.isNotApplicable && (
                      <p className="is-size-7 has-background-white has-text-primary has-text-weight-semibold">ゴールドメンテ</p>
                    )}
                  </>
                  )}
                  {title === PlanTitle.YOKUBARI && (
                  <>
                    <p className="is-size-7 has-background-white has-text-primary has-text-weight-semibold">ハイグレード車</p>
                    <p className="is-size-7 has-background-white has-text-primary has-text-weight-semibold">充実装備</p>
                    {!simulationManagerStore.nowRecommendSelection.isNotApplicable && (
                      <p className="is-size-7 has-background-white has-text-primary has-text-weight-semibold">ゴールドメンテ</p>
                    )}
                  </>
                  )}
                  {title === PlanTitle.ORIGINAL && (
                  <p className="is-size-7 has-background-white has-text-primary has-text-weight-semibold">こだわり派</p>
                  )}
                </div>

                <h3 className="has-text-primary is-size-3 has-text-centered has-text-weight-semibold is-padding-bottom-2">
                  {title}
                </h3>

                <div className={`is-margin-bottom-3 ${styles.minHeight3_5rem}`}>
                  <div className={data.grade ?? 'is-hidden'}>
                    <div className="columns is-vcentered">
                      <p className="column is-size-7">
                        <span className="is-block has-text-weight-semibold">
                          {data.term}
                          年賃貸月額定額
                        </span>
                        <span className="is-block has-text-grey">(ネット割適用後)</span>
                      </p>
                      <p className={`column has-text-weight-semibold ${styles.price} is-padding-left-1 is-padding-right-1`}>
                        {data.price}
                      </p>
                      <p className="column is-narrow is-size-7 has-text-grey">
                        円〜
                      </p>
                    </div>
                    <p className="is-size-7 has-text-centered has-text-primary">頭金・ボーナス払い0円 法定費用・手数料コミ</p>
                  </div>
                  <div className={data.grade ? 'is-hidden' : ''}>
                    <p className="is-size-6 is-padding-top-3">カラーも装備もとことんこだわりたいお客様はこちらから</p>
                  </div>
                </div>

                <div className={styles.nextStepBtnWrap}>
                  {!isOriginal || data.grade ? (
                    <button
                      className={`button is-link is-full-width is-medium has-text-weight-semibold ${styles.nextStepBtn}`}
                      type="button"
                      data-gtm={gtmCta}
                      onClick={() => goToEntryForm(data.planName)}
                    >
                      いますぐ審査・商談に進む
                      <img src={arrowWhite} alt="白矢印" />
                    </button>
                  ) : (
                    <button
                      className={`button is-primary is-full-width is-medium has-text-weight-semibold ${styles.nextStepBtn}`}
                      type="button"
                      data-gtm="gtm-newcar-detail-simulatorPC-makePlanBtn"
                      onClick={():void => {
                        onClickCustomizePlan('all', NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
                      }}
                    >
                      プランを作る
                      <img src={arrowWhite} alt="白矢印" />
                    </button>
                  )}
                </div>

                <dl className={`${styles.inner}`}>
                  <dt className="is-size-7 has-background-white has-text-weight-semibold">グレード</dt>
                  <dd className="is-size-6 has-text-grey">
                    {data.grade}
                    <span className={data.grade ? 'is-hidden' : ''}>未選択</span>
                  </dd>
                </dl>
                <dl className={`${styles.inner} ${data.color ?? 'is-hidden'}`}>
                  <dt className="is-size-7 has-background-white has-text-weight-semibold">カラー</dt>
                  <dd className="is-size-6 has-text-grey">
                    {data.color}
                  </dd>
                </dl>
                <dl className={`${styles.inner}`}>
                  <dt className="is-size-7 has-background-white has-text-weight-semibold">オプション装備</dt>
                  <dd className="is-size-6 has-text-grey">
                    {data.option}
                    <span className={data.option ? 'is-hidden' : ''}>未選択</span>
                  </dd>
                </dl>
                <dl className={`${styles.inner}`}>
                  <dt className="is-size-7 has-background-white has-text-weight-semibold">メンテナンスプラン</dt>
                  <dd className="is-size-6 has-text-grey">
                    {data.maintenance}
                    <span className={data.maintenance ? 'is-hidden' : ''}>未選択</span>

                  </dd>
                </dl>

                <div className={`${styles.customizeBtnWrap} ${data.grade ?? 'is-hidden'}`}>
                  <div className="has-text-centered">
                    <button
                      className={`button is-full-width has-text-grey ${styles.customizeBtn}`}
                      type="button"
                      data-gtm={gtmCustom}
                      onClick={():void => {
                        const id = PlanTitleToId(title);
                        switch (id) {
                          case 'ORIGINAL':
                            onClickCustomizePlan('all', NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
                            break;
                          case 'YOKUBARI':
                            onClickCustomizePlan('bodyColorAndOption', NEWCAR_CONST.SIMULATION_PLAN.ID.RICH);
                            break;
                          case 'OSUSUME':
                            onClickCustomizePlan('bodyColorAndOption', NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND);
                            break;
                          case 'SIMPLE':
                            onClickCustomizePlan('bodyColorAndOption', NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE);
                            break;
                          default:
                            break;
                        }
                        setIsSaved(false);
                      }}
                    >
                      このプランをカスタマイズ
                    </button>
                  </div>
                  {title === PlanTitle.ORIGINAL && (
                    <div className="has-text-centered">
                      <button
                        type="button"
                        className={`button is-full-width has-text-grey is-margin-top-3 ${styles.customizeBtn}`}
                        data-gtm="gtm-newcar-detail-simulatorPC-isSaved"
                        onClick={():void => onClickSave()}
                        disabled={isSaved}
                      >
                        {isSaved ? '保存済' : 'あとで見るために結果を保存'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Observer>
  );
};

export default PlanPC;
