// extracted by mini-css-extract-plugin
export const iconArrowRightBlue = "Trial-module--icon-arrow-right-blue--3npwE";
export const consultationApplicationButton = "Trial-module--consultation-application-button--1mc63";
export const arrowRed = "Trial-module--arrow-red--1T4Tp";
export const balloonCampaign = "Trial-module--balloon-campaign--xuGoU";
export const plusBtn = "Trial-module--plus-btn--20ocL";
export const active = "Trial-module--active---_tGr";
export const isUnderline = "Trial-module--is-underline--36RoR";
export const isBorderGreyLighter = "Trial-module--is-border-grey-lighter--1GpBh";
export const isBlue = "Trial-module--is-blue--2YtId";
export const isShaped = "Trial-module--is-shaped--1KC7a";
export const naviPlanBalloon = "Trial-module--navi-plan-balloon--3qBRa";
export const naviPlanText = "Trial-module--navi-plan-text--3YQrm";
export const naviPlanTextMin = "Trial-module--navi-plan-text-min--3a0su";