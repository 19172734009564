/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { PageProps } from 'gatsby';
import { useLocation } from '@reach/router';
import QueryString from 'query-string';
import CARMO_CONST from '@/common/util/const';
import { GTM } from '@/common/util/gtm';
import CarDigest from '@/common/core/model/carDigest';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import RecommendedPointsSummaryType from '@/newcar/core/model/recommendedPointsSummary';
import Layout from '@/common/ui/template/Layout';
import searchCarStoreContext from '@/newcar/ui/common/context/searchCarStoreContext';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import SimulationResultManagerStoreContext from '@/newcar/ui/common/context/SimulationResultManagerStoreContext';
import Header from '@/newcar/ui/common/organism/Header';
import Breadcrumb from '@/newcar/ui/common/molecule/Breadcrumb';
import Footer from '@/newcar/ui/common/organism/Footer';
import UserVoiceCard from '@/newcar/ui/common/organism/UserVoiceCard';
import SEO from '@/newcar/ui/detail/organism/SEO';
import BreadcrumbSchemaMarkup from '@/newcar/ui/common/molecule/BreadcrumbSchemaMarkup';
import Information from '@/newcar/ui/detail/organism/Information';
import Simulation from '@/newcar/ui/detail/organism/Simulation';
import Introduction from '@/newcar/ui/detail/organism/Introduction';
import ModalSimulateAlert from '@/newcar/ui/detail/organism/ModalSimulateAlert';
import { MAKER_NAME, MAKER_ID } from '@/newcar/util/const/maker';
import { BODY_NAME, BODY_ID } from '@/newcar/util/const/bodyType';
import NEWCAR_DEFINE from '@/newcar/util/define';
import { setAllList } from '@/newcar/util/setAllList';
import { relatedCarStore } from '@/newcar/core/store/relatedCarStore';
import NEWCAR_CONST from '@/newcar/util/const';
import StoreUtil from '@/newcar/core/store/storeUtil';
import Reviews from '@/newcar/ui/detail/organism/Reviews';
import Trial from '@/newcar/ui/detail/organism/Trial';
import Charm from '@/newcar/ui/detail/organism/Charm';
import FAQ from '@/newcar/ui/detail/organism/FAQ';
import Reason from '@/newcar/ui/detail/organism/Reason';
import Partner from '@/newcar/ui/detail/organism/Partner';
import RecommendedPointsSummary from '@/newcar/ui/detail/organism/RecommendedPointsSummary';
import Card from '@/newcar/ui/detail/molecule/Card';
import RecommendCarList from '@/newcar/ui/detail/organism/RecommendedCarList';
import * as styles from '@/newcar/ui/detail/template/index.module.styl';
import UserVoiceType from '@/newcar/core/model/userVoice';
import { jst } from '@/common/util/datetime';
import ImageStarOff from '@/newcar/ui/top/img/uservoice/starOff.svg';
import ImageStarOn from '@/newcar/ui/top/img/uservoice/star_on.svg';
import ImageStarHalf from '@/newcar/ui/top/img/uservoice/starHalf.svg';

const IMAGE_STAR_STYLE = {
  width: 16,
  height: 16,
};

interface DetailPageContext {
  car: Pick<VehicleTypes, 'id' | 'photos' | 'summary' | 'review' | 'captionImageUrl1' | 'captionImageUrl2' | 'captionImageUrl3' | 'caption1' | 'caption2' | 'caption3'>;
  digest: Pick<CarDigest, 'id__normalized' | 'makerId' | 'bodyType' | 'isMiniCar' | 'name' | 'publishStatus' | 'makerSiteUrl' | 'bodyColors' | 'wltc' | 'jc08' | 'minVehiclePrice' | 'taxedMinPrice11' | 'has4SeatOrLess' | 'has5Seat' | 'has6Seat' | 'has7Seat' | 'has8SeatOrMore' | 'thumbnailUrl' | 'isCriteoTarget' | 'hasWantCar'>;
  recommendedPointsSummary: RecommendedPointsSummaryType;
  userVoices: UserVoiceType[];
  recommendDigests: {
    sameBodyTypeDigests: CarDigest[],
    sameBodyTypeSameMakerDigests: CarDigest[],
    sameMakerDigests: CarDigest[],
  }
}

const Template: FC<PageProps<{}, DetailPageContext>> = ({ pageContext }) => {
  const {
    car,
    digest,
    recommendedPointsSummary,
    userVoices,
    recommendDigests,
  } = pageContext;

  const searchCarStore = useContext(searchCarStoreContext);
  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const simulationResultManagerStore = useContext(SimulationResultManagerStoreContext);

  const [isShowOldSimulation, setIsShowOldSimulation] = useState(false);
  const [isInvalidURL, setIsInvalidURL] = useState(false);

  const location = useLocation();
  const query = QueryString.parse(location.search);

  useEffect(() => {
    // Criteo計測タグ発火イベント
    if (digest.id__normalized && digest.isCriteoTarget) {
      GTM.dataLayer.push({
        event: 'criteo.activate',
        CriteoProductID: digest.id__normalized.toString(),
      });
    }

    // 正常時の閲覧履歴として車種IDを設定
    searchCarStore.viewCarDetail(digest.id__normalized.toString());
  }, []);

  useEffect(() => {
    // クエリをシミレーションに反映する
    if (window && query && query.g) {
      // クエリパラメータあり
      let noCount = 1;
      if (query.s1) {
        // s1はシミュレーション保存済みかを判断するパラメータなのでシミュレーションには使用しない
        noCount += 1;
      }

      // クエリパラメータのc2はパラメータ個数を表す、これがないあるいは数が合わない場合はエラー表示
      if (Number(query.c2) === (Object.keys(query).length - noCount)) {
        if (query.m2 === searchCarStore.masterNumber) {
          // 最新のマスタ番号の場合、パラメータで設定されている値を反映する
          simulationManagerStore.changeGrade(digest.id__normalized);
          simulationManagerStore.changeUserSelectFromParam({ id: digest.id__normalized, params: query });
        } else {
          // 古いマスタ番号なので、保存済みシミュレーションか確認する
          simulationResultManagerStore.checkIsSavedWithParameter({ id: digest.id__normalized, query });
          if (!simulationResultManagerStore.isSavedWithParameter) {
            // 保存済みシミュレーションでないので警告を表示し、通常の詳細ページ表示
            setIsShowOldSimulation(true);
            window.history.replaceState(null, '', location.pathname);
          }
        }
      } else {
        setIsInvalidURL(true);
        // URLからパラメータを削除
        window.history.replaceState(null, '', location.pathname);
      }
    }

    if (!Object.keys(query).length) {
      const simulationList = simulationResultManagerStore.nowSimulationList;
      if (simulationList) {
        simulationList.forEach((simulation) => {
          const selection = simulation.customerSelection;
          if (selection && selection.id === digest.id__normalized) {
            // シミュレーションを反映
            simulationManagerStore.changeUserSelect(selection);
          }
        });
      }
    }

    // シミュレーションの準備
    simulationManagerStore.changeId(digest.id__normalized.toString());
  }, [query]);

  // 対象車種をストアに保存
  relatedCarStore.setRelatedCarId(Number(digest.id__normalized));

  // storeに車種情報をセット
  setAllList();

  // bodyTypeがunselectedの時にminiかotherに変換
  const identifiedBodyType: CARMO_CONST.BODY_TYPE.ID = StoreUtil.identifyBodyType(
    digest.bodyType, digest.isMiniCar,
  );

  // パンくずリスト生成
  const breadcrumbItems: Array<{ to?: string; label: string; }> = [
    { to: NEWCAR_DEFINE.PATH.TOP, label: '新車TOP' },
    { to: NEWCAR_DEFINE.PATH.LINEUP_TOP, label: '新車一覧' },
  ];
  if (digest.makerId !== CARMO_CONST.MAKER.ID.UNSELECTED) {
    breadcrumbItems.push({
      to: NEWCAR_DEFINE.PATH.MAKER_PATH(digest.makerId),
      label: MAKER_NAME(digest.makerId as unknown as MAKER_ID),
    });
    if (digest.bodyType !== CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
      breadcrumbItems.push({
        to: NEWCAR_DEFINE.PATH.MAKER_BODYTYPE(digest.makerId, identifiedBodyType),
        label: BODY_NAME(digest.bodyType as unknown as BODY_ID),
      });
    } else if (digest.isMiniCar) {
      breadcrumbItems.push({
        to: NEWCAR_DEFINE.PATH.MAKER_BODYTYPE(digest.makerId, CARMO_CONST.BODY_TYPE.ID.MINI_CAR),
        label: BODY_NAME(CARMO_CONST.BODY_TYPE.ID.MINI_CAR as unknown as BODY_ID),
      });
    } else {
      breadcrumbItems.push({
        to: NEWCAR_DEFINE.PATH.MAKER_BODYTYPE(digest.makerId, CARMO_CONST.BODY_TYPE.ID.OTHER),
        label: BODY_NAME(CARMO_CONST.BODY_TYPE.ID.OTHER as unknown as BODY_ID),
      });
    }
  } else if (digest.bodyType !== CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
    breadcrumbItems.push({
      to: NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(digest.bodyType),
      label: BODY_NAME(digest.bodyType as unknown as BODY_ID),
    });
  } else if (digest.isMiniCar) {
    breadcrumbItems.push({
      to: NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.MINI_CAR),
      label: BODY_NAME(CARMO_CONST.BODY_TYPE.ID.MINI_CAR as unknown as BODY_ID),
    });
  } else {
    breadcrumbItems.push({
      to: NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.OTHER),
      label: BODY_NAME(CARMO_CONST.BODY_TYPE.ID.OTHER as unknown as BODY_ID),
    });
  }
  breadcrumbItems.push({ label: digest.name });

  // パンくず構造化データ用の配列
  const breadcrumbSchemaMarkupItems = breadcrumbItems.map((item) => ({
    name: item.label,
    item: item.to ? `${process.env.GATSBY_SITE_URL}${item.to}` : `${process.env.GATSBY_SITE_URL}/detail/${digest.id__normalized}/`,
  }));

  // 対象車種が販売しているか
  const isNowSale = digest.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE;

  const reload = () => {
    if (window) window.location.reload();
  };

  // 「利用者の声」全ての総合点の平均値
  const averageRatingOverall = userVoices.length > 0
    // 小数点第3位は切り上げるため 100 をかけて Math.ceil で切り上げした後に 100 で割る。
    // 例：4.504 => 450.4 => 451 => 4.51
    ? (Math.ceil((userVoices.reduce((_result, _voice) => _result + _voice.ratingOverall, 0) / userVoices.length) * 100) / 100).toPrecision(3)
    : 0;

  return (
    <Layout>
      <SEO {...digest} />
      <BreadcrumbSchemaMarkup items={breadcrumbSchemaMarkupItems} />
      <Header>
        <Breadcrumb
          items={breadcrumbItems}
        />
      </Header>

      <main className="has-background-white-ter is-padding-bottom-0">
        <section className="has-background-white">
          <div className="container is-margin-bottom-5">
            <Information {...car} {...digest} isNowSale={isNowSale} />
          </div>
        </section>

        {recommendedPointsSummary
          ? (
            <section className="container">
              <Card>
                <RecommendedPointsSummary
                  className={`${styles.recommendedPoints} is-margin-7`}
                  carName={digest.name}
                  points={[
                    recommendedPointsSummary.point1,
                    recommendedPointsSummary.point2,
                    recommendedPointsSummary.point3,
                  ]}
                  recommendedPointsSummary={recommendedPointsSummary}
                />
              </Card>
            </section>
          )
          : <></>}

        {/* 口コミ */}
        <Reviews />

        {/* シミレーション */}
        {isNowSale && (
          <Simulation {...digest} />
        )}

        {/* 相談申込 */}
        {isNowSale && (
          <Trial />
        )}

        {/* 三冠 */}
        <Charm {...digest} isNowSale={isNowSale} />

        {/* FAQ */}
        <FAQ />

        {/* 人気の理由 */}
        <Reason />

        {/* 車種紹介 */}
        {isNowSale && (
          <section className="container">
            <Card className="is-padding-left-3 is-padding-right-3">
              <Introduction
                {...car}
                point1={recommendedPointsSummary?.point1}
                point2={recommendedPointsSummary?.point2}
                point3={recommendedPointsSummary?.point3}
              />
            </Card>
          </section>
        )}

        {/* おすすめ車両 */}
        <section>
          <RecommendCarList {...car} {...digest} recommendDigests={recommendDigests} isNowSale={isNowSale} />
        </section>

        {/* 提携先 */}
        <Partner />

        {/* 利用者の声 */}
        {userVoices.length > 0 && (
          <section className="container is-hidden-desktop">
            <Card>
              <p className="has-text-weight-semibold has-text-centered">
                {userVoices[0].maker}
                &nbsp;
                {userVoices[0].carModel}
              </p>
              <h2 className="title is-3 has-text-centered is-margin-bottom-3 is-margin-top-3">ご利用者の声</h2>
              <div className={`has-text-centered ${styles.userVoiceRating}`}>
                総合評価
                <div className={styles.userVoiceRatingImages}>
                  {
                    // 4.00 - 4.49 -> ★★★★ + ☆
                    // 4.50 - 4.99 -> ★★★★ + 半分の星
                    // 5.00 - 5.00 -> ★★★★ + ★
                    Array(5).fill(ImageStarOn).map((image, index) => {
                      let $image = image;
                      if (Number(averageRatingOverall) < (index + 1)) {
                        $image = Number(averageRatingOverall) >= (index + 0.5) ? ImageStarHalf : ImageStarOff;
                      }
                      return <img {...IMAGE_STAR_STYLE} src={$image} alt="星" />;
                    })
                  }
                </div>
                {averageRatingOverall}
              </div>
              <div className={`columns is-mobile is-padding-left-4 is-padding-right-4 is-margin-top-5 ${styles.userVoiceContainer} ${userVoices.length === 1 ? 'is-centered' : ''}`}>
                {userVoices.sort((a, b) => jst(b.postedAt).unix() - jst(a.postedAt).unix()).map((userVoice) => (
                  <UserVoiceCard
                    key={userVoice.comment}
                    className={`${styles.userVoiceItem} page-detail`}
                    {...userVoice}
                    postedAt={jst(userVoice.postedAt)}
                  />
                ))}
              </div>
            </Card>
          </section>
        )}

      </main>

      <Footer />

      {/* 古い保存済みシミュレーションを変更しようとした場合のアラートダイアログ */}
      <div className={`modal ${(isShowOldSimulation || isInvalidURL) && 'is-active'}`}>
        <div className="modal-background" onClick={(): void => { setIsShowOldSimulation(false); setIsInvalidURL(false); }} />
        <div className="modal-content has-background-white is-padding-5 is-padding-top-0">
          <div className="main-card has-background-primary detail_border is-size-5" />
          {isShowOldSimulation && (
            <p className="is-padding-top-5">
              車種情報が更新されています。
              <br />
              最新の車種情報でもう一度、料金をご確認ください。
            </p>
          )}
          {isInvalidURL && (
            <p>
              URLが誤っています。料金シミュレーションを表示できません。
            </p>
          )}
          <div className="columns is-mobile is-margin-top-5 has-text-centered is-centered">
            <button type="button" className="column button is-large is-half" onClick={reload}>
              OK
            </button>
          </div>
        </div>
      </div>

      <ModalSimulateAlert />

    </Layout>
  );
};

export default Template;
