/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import { Observer } from 'mobx-react-lite';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import * as styles from '@/newcar/ui/detail/organism/modal/ModalSP.module.styl';

interface ModalOptionSPProps {
  isShow: boolean;
  hasWantCar: boolean;
}

const ModalOptionSP: FC<ModalOptionSPProps> = ({ isShow, hasWantCar }) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isShow) {
      setStyle({});
    } else {
      // コンテンツの高さが一番高いところで揃ってしまうので非表示の時は高さを0にする
      setTimeout(() => setStyle({ height: '0px' }), 400);
    }
  }, [isShow]);

  // もらえるオプションの対象かどうか
  const wantCarTarget = (): boolean => {
    const selectTerm: NEWCAR_CONST.TERM.ID = simulationManagerStore.nowSelectTerm;
    return ((
      selectTerm === NEWCAR_CONST.TERM.ID.YEAR_11
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_10
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_9
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_8
      || selectTerm === NEWCAR_CONST.TERM.ID.YEAR_7
    ) && (
      hasWantCar
    ));
  };

  // オプションごとの配列を受け取ってチェックボックスを生成する
  const checkBox = (list: any[], key: string, onChange: Function) => list.map((value, index) => (
    <div className={`${styles.checkboxRow} is-padding-1`} key={index.toString()}>
      <input
        type="checkbox"
        name={key}
        id={`${key}-${value.id}`}
        checked={value.isChecked}
        disabled={value.isStandard}
        onChange={(event) => onChange(event.target.checked ? value.id : value.notid)}
      />
      <label htmlFor={`${key}-${value.id}`} className="box is-padding-5">
        <span>{value.optionType}</span>
        {value.isStandard ? (
          <span>標準装備</span>
        ) : (
          <span>
            {`+${CARMO_CONST.COMMA_FORMAT(value.price)}円`}
          </span>
        )}
      </label>
    </div>
  ));

  return (
    <Observer>
      {() => (
        <div className={`${styles.content} is-padding-5`} style={style} data-type="option-setting">
          <h2 className="has-text-centered is-margin-bottom-3 is-size-3 has-text-weight-light">オプションをえらぶ</h2>
          <div className="is-margin-bottom-6">

            {/* カーナビ */}
            {checkBox(simulationManagerStore.carNaviList, 'car-navi', (id: string) => simulationManagerStore.changeCarNavi(id))}

            {/* ETC */}
            {checkBox(simulationManagerStore.etcList, 'etc', (id: string) => simulationManagerStore.changeETC(id))}

            {/* バックカメラ */}
            {checkBox(simulationManagerStore.backCameraList, 'back-camera', (id: string) => simulationManagerStore.changeBackCamera(id))}

            {/* フロアマット */}
            {checkBox(simulationManagerStore.floorMatList, 'floor-mat', (id: string) => simulationManagerStore.changeFloorMat(id))}

            {/* ドアバイザー */}
            {checkBox(simulationManagerStore.doorVisorList, 'door-visor', (id: string) => simulationManagerStore.changeDoorVisor(id))}

            {/* 希望ナンバー */}
            {checkBox(simulationManagerStore.wantNoList, 'want-no', (id: string) => simulationManagerStore.changeWantNo(id))}

            {/* もらえるプラン */}
            {wantCarTarget() && checkBox(
              [{
                optionType: 'もらえるオプション※1',
                price: simulationManagerStore.wantCarPrice,
                id: 'yes',
                notid: 'no',
                isChecked: simulationManagerStore.nowCar?.wantCar === 'yes',
              }],
              'want-car',
              (id: string) => simulationManagerStore.changeWantCar(id),
            )}

            <div className="is-size-5">
              {wantCarTarget() && (
                <p className="is-margin-top-5 text-indent">※1「もらえるオプション」はリース期間満了後にクルマがもらえるオプションです</p>
              )}
              <p className="is-margin-top-5 text-indent">※メーカーオプションなど、上記以外のオプションも搭載可能ですので商談時にご相談ください</p>
              <p className="is-margin-top-5 text-indent">
                ※上記オプションは純正品やグレード専用品でない場合もございます。ご指定がございましたら一度ご確認しますので、
                <a href="/contact" target="_blank" className={`${styles.underline}`}>お問い合わせ</a>
                くださいませ。お申し込み後の変更も承っております。
              </p>
              <p className="is-margin-top-5 text-indent">※表示金額は月額税込の金額です</p>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default ModalOptionSP;
