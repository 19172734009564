/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext } from 'react';
import * as styles from '@/newcar/ui/detail/organism/ModalSimulateAlert.module.styl';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import { Observer } from 'mobx-react-lite';

const ModalSimulateAlert: FC = () => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);

  return (
    <Observer>
      {() => (
        <div className={`modal ${simulationManagerStore.isShowSimulateAlert ? styles.displayFlex : ''}`}>
          <div className={styles.alertBackground} />
          <div className="modal-content has-background-white">
            <div className="accent-line-long has-background-primary" />
            <div className="columns is-mobile is-vcentered is-padding-5 is-padding-bottom-0">
              <div className="column" />
              <div className="column is-narrow">
                <a onClick={() => simulationManagerStore.setIsShowSimulateAlert(false)}>
                  閉じる
                </a>
              </div>
            </div>
            <div className="has-text-centered is-padding-4">
              <p>
                リース期間の変更によりメンテプランの選択が解除されました。
                <br />
                ※プラチナプランはリース期間6年～11年でご加入いただけます。
              </p>
              <div className="columns is-mobile is-padding-top-5 is-padding-bottom-5 has-text-centered is-centered">
                <button className={`column button is-4 ${styles.detailDialogButton}`} type="button" onClick={() => simulationManagerStore.setIsShowSimulateAlert(false)}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default ModalSimulateAlert;
