/* eslint-disable max-len */

import { observable, action, computed } from 'mobx';
import { store } from '@/common/core/store/decorator';
// import NEWCAR_CONST from '@/newcar/util/const';
import CARMO_CONST from '@/common/util/const';
import RelatedRanking from '@/newcar/core/model/relatedRanking';
import CarDigest from '@/common/core/model/carDigest';

// store系
import { searchCarStore } from '@/newcar/core/store/searchCarStore';
import StoreUtil from '@/newcar/core/store/storeUtil';

// 個別ページのランキングで表示する件数
const DETAIL_RANKING_MAX = 5;

/**
 * 車両概要のリストをランク付けする
 * @param  {CarDigest[]} carDigests 車両概要のリスト
 * @return {RelatedRanking[]}       ランク付けされた車両概要
 */
function rankCarDigest(carDigests: CarDigest[]): RelatedRanking[] {
  if (!searchCarStore.allCarDetails) {
    return [];
  }

  // 人気度で並び替え
  carDigests.sort((a: CarDigest, b: CarDigest) => StoreUtil.comparePopularityScore(a, b));

  // 上位から最大5件を取り出し
  let skipped = 0;
  const result: RelatedRanking[] = [];
  for (let i = 0; i < carDigests.length; i += 1) {
    const carDigest = carDigests[i];
    const targetCarDetail = searchCarStore.allCarDetails.find(
      (detail) => (detail.id === carDigest.id__normalized),
    );
    if (targetCarDetail && carDigest.publishStatus) {
      result.push({
        rank: i + 1 - skipped,
        makerId: carDigest.makerId,
        carId: carDigest.id__normalized,
        carName: carDigest.name,
        imgUrl: carDigest.thumbnailUrl,
        price: carDigest.taxedMinPrice11,
        summary: targetCarDetail.summary || '',
      });
    } else {
      skipped += 1;
    }
    if (result.length >= DETAIL_RANKING_MAX) {
      break;
    }
  }
  return result;
}

/**
 * 現在の車種に関連する車種ランキングの管理
 * @class RelatedCarStore
 */
@store({ persist: true, name: 'RelatedCarStore' })
export default class RelatedCarStore {
  // 現在の車種
  @observable targetCarId = 0;

  /**
   * 対象車種のランキング対象となるボデイタイプ名
   * @readonly
   * @memberof RelatedCarStore
   */
  @computed
  get relatedBodyTypeName(): string {
    // 対象車種を抽出
    const nowCar = searchCarStore.allCarDigests.find((digest:CarDigest) => digest.id__normalized === this.targetCarId as unknown as string);
    if (nowCar) {
      const targetBodyType = StoreUtil.identifyBodyType(nowCar.bodyType, nowCar.isMiniCar);
      return CARMO_CONST.BODY_TYPE.NAME(targetBodyType);
    }
    return '';
  }

  /**
     * 対象車種と同一ボデイタイプのランキングTOP5
     * @readonly
     * @type {{ RelatedRanking[] }
     * @memberof RelatedCarStore
     */
  @computed
  get relatedBodyTypeRanking():RelatedRanking[] {
    if (!searchCarStore.allCarDetails) {
      return [];
    }
    // 対象車種を抽出
    const nowCar: CarDigest | undefined = searchCarStore.allCarDigests.find(
      (digest: CarDigest) => (digest.id__normalized === this.targetCarId as unknown as string),
    );
    if (!nowCar) {
      return [];
    }

    const targetBodyType = StoreUtil.identifyBodyType(nowCar.bodyType, nowCar.isMiniCar);
    // 対象車種と同一のボディタイプを抽出
    const sameList = searchCarStore.allCarDigests.filter((car: CarDigest) => ((car.bodyType === targetBodyType)
                || ((targetBodyType === CARMO_CONST.BODY_TYPE.ID.MINI_CAR) && car.isMiniCar)
                || ((targetBodyType === CARMO_CONST.BODY_TYPE.ID.OTHER) && (car.bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED) && !car.isMiniCar)));
    return rankCarDigest(sameList);
  }

  /**
   * 対象車種のランキング対象となるメーカー名
   * @readonly
   * @memberof RelatedCarStore
   */
  @computed
  get relatedMakerName():string {
    // 対象車種を抽出
    const nowCar = searchCarStore.allCarDigests.find((digest:CarDigest) => digest.id__normalized === this.targetCarId as unknown as string);
    return nowCar?.makerId.toString() ?? '';
  }

  /**
  * 対象車種と同一メーカーのランキングTOP5
  * @readonly
  * @type {{ RelatedRanking[] }
  * @memberof RelatedCarManager
  */
  @computed
  get relatedMakerRanking(): RelatedRanking[] {
    if (!searchCarStore.allCarDetails) {
      return [];
    }
    // 対象車種を抽出
    const nowCar: CarDigest | undefined = searchCarStore.allCarDigests.find(
      (digest: CarDigest) => digest.id__normalized === this.targetCarId as unknown as string,
    );
    if (!nowCar) {
      return [];
    }

    // 対象車種と同一のメーカーを抽出
    const sameList: CarDigest[] = searchCarStore.allCarDigests.filter(
      (x:CarDigest) => x.makerId === nowCar.makerId,
    );
    return rankCarDigest(sameList);
  }

  /**
   * 対象車種と同一メーカー×同一ボデイタイプのランキングTOP5
   * @readonly
   * @type {{ RelatedRanking[] }
   * @memberof RelatedCarManager
   */
  @computed
  get relatedMakerAndBodyTypeRanking(): RelatedRanking[] {
    if (!searchCarStore.allCarDetails) {
      return [];
    }
    // 対象車種を抽出
    const nowCar: CarDigest | undefined = searchCarStore.allCarDigests.find((digest: CarDigest) => digest.id__normalized === this.targetCarId as unknown as string);
    if (!nowCar) {
      return [];
    }

    const targetBodyType: CARMO_CONST.BODY_TYPE.ID = StoreUtil.identifyBodyType(nowCar.bodyType, nowCar.isMiniCar);
    // 対象車種と同一のボディタイプを抽出
    const sameList: CarDigest[] = searchCarStore.allCarDigests.filter((x) => (
      (x.makerId === nowCar.makerId)
            && (x.bodyType === targetBodyType
                || (targetBodyType === CARMO_CONST.BODY_TYPE.ID.MINI_CAR && x.isMiniCar)
                || (targetBodyType === CARMO_CONST.BODY_TYPE.ID.OTHER && x.bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED && !x.isMiniCar))));
    return rankCarDigest(sameList);
  }

  /**
   * action:現在車種を設定
   * @param {number} id
   * @memberof RelatedCarManager
   */
  @action
  public setRelatedCarId(id: number):number {
    this.targetCarId = id;
    return this.targetCarId;
  }
}

export const relatedCarStore = new RelatedCarStore();
